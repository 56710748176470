
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.cc-quarry-and-pit-crushing-picture img {
	width: 683px;
}

.ready-to-load-img .cc-quarry-and-pit-crushing-picture-background, .ready-to-load-img .cc-quarry-and-pit-crushing-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.webp");
			}
		}
	}
}
.ready-to-load-img .cc-quarry-and-pit-crushing-picture-box-background, .ready-to-load-img .cc-quarry-and-pit-crushing-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/crushing/cc-quarry-and-pit-crushing-2x.webp");
		}
	}
}

.cc-quarry-and-pit-crushing-picture-background-ratio, .cc-quarry-and-pit-crushing-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.crushing-demolition-picture img {
	width: 683px;
}

.ready-to-load-img .crushing-demolition-picture-background, .ready-to-load-img .crushing-demolition-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/crushing/crushing-demolition.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/crushing/crushing-demolition.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/crushing/crushing-demolition-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/crushing/crushing-demolition-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/crushing/crushing-demolition-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/crushing/crushing-demolition-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/crushing/crushing-demolition.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/crushing/crushing-demolition.webp");
			}
		}
	}
}
.ready-to-load-img .crushing-demolition-picture-box-background, .ready-to-load-img .crushing-demolition-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/crushing/crushing-demolition.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/crushing/crushing-demolition.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/crushing/crushing-demolition-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/crushing/crushing-demolition-2x.webp");
		}
	}
}

.crushing-demolition-picture-background-ratio, .crushing-demolition-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.crushing-quarry-picture img {
	width: 683px;
}

.ready-to-load-img .crushing-quarry-picture-background, .ready-to-load-img .crushing-quarry-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/crushing/crushing-quarry.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/crushing/crushing-quarry.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/crushing/crushing-quarry-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/crushing/crushing-quarry-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/crushing/crushing-quarry-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/crushing/crushing-quarry-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/crushing/crushing-quarry.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/crushing/crushing-quarry.webp");
			}
		}
	}
}
.ready-to-load-img .crushing-quarry-picture-box-background, .ready-to-load-img .crushing-quarry-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/crushing/crushing-quarry.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/crushing/crushing-quarry.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/crushing/crushing-quarry-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/crushing/crushing-quarry-2x.webp");
		}
	}
}

.crushing-quarry-picture-background-ratio, .crushing-quarry-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.demolition-recycling-picture img {
	width: 683px;
}

.ready-to-load-img .demolition-recycling-picture-background, .ready-to-load-img .demolition-recycling-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/crushing/demolition-recycling.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/crushing/demolition-recycling.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/crushing/demolition-recycling-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/crushing/demolition-recycling-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/crushing/demolition-recycling-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/crushing/demolition-recycling-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/crushing/demolition-recycling.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/crushing/demolition-recycling.webp");
			}
		}
	}
}
.ready-to-load-img .demolition-recycling-picture-box-background, .ready-to-load-img .demolition-recycling-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/crushing/demolition-recycling.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/crushing/demolition-recycling.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/crushing/demolition-recycling-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/crushing/demolition-recycling-2x.webp");
		}
	}
}

.demolition-recycling-picture-background-ratio, .demolition-recycling-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
