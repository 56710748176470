
.page-custom-crushing {
	@import (reference) "crushing-images";
	.demolition {
		.ready-to-load-img.crushing-demolition-picture-box-background();
	}
	.quarry {
		.ready-to-load-img.crushing-quarry-picture-box-background;
	}

}