

.page-municipal {
	@import (reference) "municipal-images";
	.rock {
		.ready-to-load-img.municipal-aggregates-picture-box-background();
	}
	.utility {
		.ready-to-load-img.municipal-utility-picture-box-background;
	}
	.roads {
		.ready-to-load-img.road-construction-095-picture-box-background;
	}

}