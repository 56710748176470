
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.truck-contract-picture img {
	width: 683px;
}

.ready-to-load-img .truck-contract-picture-background, .ready-to-load-img .truck-contract-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/trucking/truck-contract.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/trucking/truck-contract.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/trucking/truck-contract-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/trucking/truck-contract-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/trucking/truck-contract-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/trucking/truck-contract-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/trucking/truck-contract.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/trucking/truck-contract.webp");
			}
		}
	}
}
.ready-to-load-img .truck-contract-picture-box-background, .ready-to-load-img .truck-contract-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/trucking/truck-contract.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/trucking/truck-contract.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/trucking/truck-contract-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/trucking/truck-contract-2x.webp");
		}
	}
}

.truck-contract-picture-background-ratio, .truck-contract-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
