.page-landing {
	/*
	@import (reference) "home-images";
	.residential {
		.ready-to-load-img.residential-picture-box-background();
	}
	.commercial {
		.ready-to-load-img.commercial-picture-box-background();
	}
	.municipal {
		.ready-to-load-img.municipal-picture-box-background();
	}
	.trucking {
		.ready-to-load-img.trucking-picture-box-background();
	}
	.aggregates {
		.ready-to-load-img.aggregates-picture-box-background();
	}
	.crushing {
		.ready-to-load-img.crushing-picture-box-background();
	}
	.runway {
		.ready-to-load-img.runway-picture-box-background();
	}
	*/

}