
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.aggregates-picture img {
	width: 512.5px;
}

.ready-to-load-img .aggregates-picture-background, .ready-to-load-img .aggregates-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home/aggregates.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/aggregates.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/aggregates-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/aggregates-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-picture-box-background, .ready-to-load-img .aggregates-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/home/aggregates.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/aggregates.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/aggregates-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/aggregates-2x.webp");
		}
	}
}

.aggregates-picture-background-ratio, .aggregates-picture-background-with-ratio {
	.ratio(1.3326829268292684);
	background-position: center center;
	background-size: cover;
}

.commercial-excavation-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-excavation-picture-background, .ready-to-load-img .commercial-excavation-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home/commercial-excavation.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/commercial-excavation.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/home/commercial-excavation-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/commercial-excavation-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/commercial-excavation-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/commercial-excavation-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/home/commercial-excavation.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home/commercial-excavation.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-excavation-picture-box-background, .ready-to-load-img .commercial-excavation-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/home/commercial-excavation.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/commercial-excavation.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/commercial-excavation-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/commercial-excavation-2x.webp");
		}
	}
}

.commercial-excavation-picture-background-ratio, .commercial-excavation-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.commercial-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-picture-background, .ready-to-load-img .commercial-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home/commercial.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home/commercial.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/home/commercial-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/commercial-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home/commercial-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/commercial-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/home/commercial.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home/commercial.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-picture-box-background, .ready-to-load-img .commercial-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/home/commercial.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home/commercial.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home/commercial-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/commercial-2x.webp");
		}
	}
}

.commercial-picture-background-ratio, .commercial-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.crushing-picture img {
	width: 683px;
}

.ready-to-load-img .crushing-picture-background, .ready-to-load-img .crushing-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home/crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home/crushing.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/home/crushing-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/crushing-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home/crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/crushing-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/home/crushing.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home/crushing.webp");
			}
		}
	}
}
.ready-to-load-img .crushing-picture-box-background, .ready-to-load-img .crushing-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/home/crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home/crushing.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home/crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/crushing-2x.webp");
		}
	}
}

.crushing-picture-background-ratio, .crushing-picture-background-with-ratio {
	.ratio(0.7503660322108345);
	background-position: center center;
	background-size: cover;
}

.eikmeier-picture img {
	width: 683px;
}

.ready-to-load-img .eikmeier-picture-background, .ready-to-load-img .eikmeier-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home/eikmeier.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/eikmeier.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/home/eikmeier-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/eikmeier-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/eikmeier-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/eikmeier-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/home/eikmeier.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/home/eikmeier.webp");
			}
		}
	}
}
.ready-to-load-img .eikmeier-picture-box-background, .ready-to-load-img .eikmeier-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/home/eikmeier.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/eikmeier.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/eikmeier-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/eikmeier-2x.webp");
		}
	}
}

.eikmeier-picture-background-ratio, .eikmeier-picture-background-with-ratio {
	.ratio(0.7503660322108345);
	background-position: center center;
	background-size: cover;
}

.harvesting-picture img {
	width: 672px;
}

.ready-to-load-img .harvesting-picture-background, .ready-to-load-img .harvesting-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home/harvesting.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/harvesting.webp");
	}
	
	
	@media (max-width: 336px) {

		background-image: url("@{cdn1}/img/home/harvesting-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/harvesting-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/harvesting-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/harvesting-2x.webp");
		}
		@media (max-width: 336px) {

			background-image: url("@{cdn1}/img/home/harvesting.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home/harvesting.webp");
			}
		}
	}
}
.ready-to-load-img .harvesting-picture-box-background, .ready-to-load-img .harvesting-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/home/harvesting.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/harvesting.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/harvesting-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/harvesting-2x.webp");
		}
	}
}

.harvesting-picture-background-ratio, .harvesting-picture-background-with-ratio {
	.ratio(0.75);
	background-position: center center;
	background-size: cover;
}

.municipal-picture img {
	width: 672px;
}

.ready-to-load-img .municipal-picture-background, .ready-to-load-img .municipal-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home/municipal.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home/municipal.webp");
	}
	
	
	@media (max-width: 336px) {

		background-image: url("@{cdn2}/img/home/municipal-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/municipal-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home/municipal-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/municipal-2x.webp");
		}
		@media (max-width: 336px) {

			background-image: url("@{cdn2}/img/home/municipal.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home/municipal.webp");
			}
		}
	}
}
.ready-to-load-img .municipal-picture-box-background, .ready-to-load-img .municipal-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/home/municipal.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home/municipal.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home/municipal-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home/municipal-2x.webp");
		}
	}
}

.municipal-picture-background-ratio, .municipal-picture-background-with-ratio {
	.ratio(0.75);
	background-position: center center;
	background-size: cover;
}

.residential-picture img {
	width: 683px;
}

.ready-to-load-img .residential-picture-background, .ready-to-load-img .residential-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home/residential.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home/residential.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/home/residential-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/residential-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home/residential-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/residential-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/home/residential.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home/residential.webp");
			}
		}
	}
}
.ready-to-load-img .residential-picture-box-background, .ready-to-load-img .residential-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/home/residential.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home/residential.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home/residential-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home/residential-2x.webp");
		}
	}
}

.residential-picture-background-ratio, .residential-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.runway-picture img {
	width: 341.5px;
}

.ready-to-load-img .runway-picture-background, .ready-to-load-img .runway-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home/runway.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/runway.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/runway-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/runway-2x.webp");
		}
	}
}
.ready-to-load-img .runway-picture-box-background, .ready-to-load-img .runway-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/home/runway.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home/runway.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home/runway-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home/runway-2x.webp");
		}
	}
}

.runway-picture-background-ratio, .runway-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.trucking-picture img {
	width: 683px;
}

.ready-to-load-img .trucking-picture-background, .ready-to-load-img .trucking-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home/trucking.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/trucking.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/home/trucking-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/trucking-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/trucking-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/trucking-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/home/trucking.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home/trucking.webp");
			}
		}
	}
}
.ready-to-load-img .trucking-picture-box-background, .ready-to-load-img .trucking-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/home/trucking.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home/trucking.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home/trucking-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home/trucking-2x.webp");
		}
	}
}

.trucking-picture-background-ratio, .trucking-picture-background-with-ratio {
	.ratio(0.7503660322108345);
	background-position: center center;
	background-size: cover;
}
