
.page-residential {
	.residential-septic {
		.ready-to-load-img.septic-design-install-picture-box-background();
	}
	.residential-septic-maint {
		.ready-to-load-img.septic-maintenance-picture-box-background();
	}
	.residential-grading {
		.ready-to-load-img.site-grading-picture-box-background();
	}
	.residential-foundation {
		.ready-to-load-img.foundation-picture-box-background();
	}
	.shoreline-improve {
		.ready-to-load-img.shoreline-picture-box-background();
	}

}