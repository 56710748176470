
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.employees-2-picture img {
	width: 683px;
}

.ready-to-load-img .employees-2-picture-background, .ready-to-load-img .employees-2-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/employment/employees-2.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/employment/employees-2.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/employment/employees-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/employment/employees-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/employment/employees-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/employment/employees-2-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/employment/employees-2.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/employment/employees-2.webp");
			}
		}
	}
}
.ready-to-load-img .employees-2-picture-box-background, .ready-to-load-img .employees-2-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/employment/employees-2.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/employment/employees-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/employment/employees-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/employment/employees-2-2x.webp");
		}
	}
}

.employees-2-picture-background-ratio, .employees-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.employment-employee-picture img {
	width: 683px;
}

.ready-to-load-img .employment-employee-picture-background, .ready-to-load-img .employment-employee-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/employment/employment-employee.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/employment/employment-employee.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/employment/employment-employee-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/employment/employment-employee-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/employment/employment-employee-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/employment/employment-employee-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/employment/employment-employee.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/employment/employment-employee.webp");
			}
		}
	}
}
.ready-to-load-img .employment-employee-picture-box-background, .ready-to-load-img .employment-employee-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/employment/employment-employee.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/employment/employment-employee.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/employment/employment-employee-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/employment/employment-employee-2x.webp");
		}
	}
}

.employment-employee-picture-background-ratio, .employment-employee-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.employment-filter-picture img {
	width: 683px;
}

.ready-to-load-img .employment-filter-picture-background, .ready-to-load-img .employment-filter-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/employment/employment-filter.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/employment/employment-filter.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/employment/employment-filter-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment/employment-filter-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/employment/employment-filter-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment/employment-filter-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/employment/employment-filter.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/employment/employment-filter.webp");
			}
		}
	}
}
.ready-to-load-img .employment-filter-picture-box-background, .ready-to-load-img .employment-filter-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/employment/employment-filter.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/employment/employment-filter.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/employment/employment-filter-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment/employment-filter-2x.webp");
		}
	}
}

.employment-filter-picture-background-ratio, .employment-filter-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
