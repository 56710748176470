
.job-application-email {
	.t2 {
		grid-column: span 2;
	}	
	.t3 {
		grid-column: span 3;
	}	
	.t4 {
		grid-column: span 4;
	}	
	.t6 {
		grid-column: span 6;
	}	
	.t8 {
		grid-column: span 8;
	}	
	.t10 {
		grid-column: span 10;
	}	
}