.page-contact {
	@import (reference) "contact-images";
	.contact-message {
		margin-top: 60px;
		h2 {
			.phudu(35);
		}
		a {
			color: @tan-dark;
		}
	}
	
	.contact-logos {
		.ready-to-load-img .contact-logos-picture-box-background();
	}
}