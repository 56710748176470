

.page-aggregates {
	@import (reference) "aggregates-images";
	.screened-black-dirt {
		.ready-to-load-img .aggregates-screened-black-dirt-picture-box-background();
	}
	.washed-sand {
		.ready-to-load-img .aggregates-washed-sand-picture-box-background();
	}
	.pea-rock {
		.ready-to-load-img .aggregates-pea-rock-picture-box-background();
	}
	.crushed-rock {
		.ready-to-load-img .aggregates-crushed-rock-picture-box-background();
	}
	.roof-rock {
		.ready-to-load-img .aggregates-roof-rock-picture-box-background();
	}
	.washed-rock {
		.ready-to-load-img .aggregates-washed-rock-picture-box-background();
	}
	.roof-rock-big {
		.ready-to-load-img .aggregates-roof-rock-big-picture-box-background();
	}
	.boulders {
		.ready-to-load-img .aggregates-boulders-picture-box-background();
	}
	.placeholder-rock {
		background-color: #eeeeee;
	}
	
	.gravel-class-5 {
		.ready-to-load-img .aggregates-gravel-class-5-picture-box-background();
	}
	
	.concrete-class-5 {
		.ready-to-load-img .aggregates-concrete-class-5-picture-box-background();
	}
	
	.granite-class-2 {
		.ready-to-load-img .aggregates-granite-class-2-picture-box-background();
	}

	
	.onsite {
		.ready-to-load-img .aggregates-onsite-crushing-picture-box-background();

	}
	
	.aggregate-types {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		column-gap: 50px;
		row-gap: 80px;
		.aggregate-type {
			width: 170px;
			.img {
				aspect-ratio: 1;
			}
			.name {
				margin-top: 20px;
			}
		}
	}
	

}