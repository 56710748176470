// .container {
// 	max-width: 100%;
// 	margin: auto;
	
// 	padding-left: @page-side-padding;
// 	padding-right: @page-side-padding;
	
// 	.desktop({
// 		max-width: @container-width;
// 		padding-left: 0;
// 		padding-right: 0;
// 	});
// }

@side-gutter: 85px;
@middle-gutter: 65px;
@minimum-gutter: 15px;

.container-wrapper {
	display: grid;
	grid-template-columns: minmax(0, 1fr) @side-gutter minmax(100px, 600px) @middle-gutter minmax(100px, 536px) @side-gutter minmax(0, 1fr);

	& > section {
		display: contents;
	}
	.container {
		grid-column: 3 / span 3;
	}
	.container-left {
		grid-column: 3;
	}
	.container-right {
		grid-column: 5;
	}
	
	.time-period {
		grid-column: 3 / span 3;
	}
	
	
	.tablet-and-smaller({
		grid-template-columns: minmax(0, 1fr) @minimum-gutter minmax(100px, 600px) @middle-gutter minmax(100px, 536px) @minimum-gutter minmax(0, 1fr);
		.container-left {
			grid-column: 3 / span 3;
		}
		.container-right {
			grid-column: 3 / span 3;
		}
	});
}

.is-container {
	container: is-container / inline-size;

}


.full-back {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.use-picture-as-background {
	position: relative;
	isolation: isolate;
	& > picture:first-child {
		user-select: none;
		img {
			position: absolute;
			width: 100%;
			height: 100% !important;
			object-fit: cover;
			object-position: center center;
			z-index: -1;
			user-select: none;
		}
	}
}

.ratio(@ratio) {
	&:before {
		content: '\00a0';
		display: inline-block;
		width: 0;
		font-size: 0;
		height: 0;
		padding-top: @ratio / 2 * 100%;
		padding-bottom: @ratio / 2 * 100%;
		vertical-align: middle;
	}
	@supports (aspect-ratio: @ratio) {
		aspect-ratio: 1 / @ratio;
	}
	@supports (aspect-ratio: @ratio) {
		&:before {
			display: none;
		}
	}
}
/*
.ei-grid {
	#ei-grid-1.grid;
}

.ei-gutterless-grid {
	#ei-grid-1.gutterless-grid;
}
*/

.ib, .inline-block {
	display: inline-block;
}

.clear {
	clear: both;
}

.clear-inner {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.background-color-fade(@color, @opamount) {
    background-color: @color;
    background-color: fade(@color, @opamount);
}

.horz-back-grad(@left, @right) {
  background: -webkit-linear-gradient(left, @left , @right); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, @left , @right); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, @left , @right); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, @left , @right); /* Standard syntax */
}

.nowrap {
    white-space: nowrap;
}

.fs(@font-size) {
	font-size: (@base-font-size * @font-size / 10);
}

@media (max-width: @container-width) {
	.only-desktop {
		display: none;
	}
}

.desktop(@rules) {
	@media(min-width: @container-width) {
		@rules();
	}
}

.desktop-and-smaller(@rules) {
    @media (max-width: @container-width) {
        @rules();
    }
}

.tablet(@rules) {
	@media(min-width: @tablet-width) {
		@rules();
	}
}

.tablet-and-smaller(@rules) {
    @media (max-width: @tablet-width) {
        @rules();
    }
}

.big-phone-and-smaller(@rules) {
    @media (max-width: @big-phone-width) {
        @rules();
    }
}

.big-phone(@rules) {
	@media(min-width: @big-phone-width) {
		@rules();
	}
}

.phone-and-smaller(@rules) {
    @media (max-width: @phone-width) {
        @rules();
    }
}

.phone(@rules) {
	@media(min-width: @phone-width) {
		@rules();
	}
}

.iphone-landscape-and-smaller(@rules) {
    @media (max-width: @iphone-4-landscape-width) {
        @rules();
    }
}

.iphone-landscape(@rules) {
	@media(min-width: @iphone-4-landscape-width) {
		@rules();
	}
}

.primative(@rules) {
	.ie6, .ie7, .ie8 {
		@rules();
	}
}


