

.page-about {
	@import (reference) "about-images";
	/*
	.truck-1 {
		.ready-to-load-img .about-truck-1-picture-box-background();
	}
	.truck-2 {
		.ready-to-load-img .about-truck-2-picture-box-background();
	}
	.earthmoving-1 {
		.ready-to-load-img .about-earthmoving-1-picture-box-background();
	}
	.earthmoving-2 {
		.ready-to-load-img .about-earthmoving-2-picture-box-background();
	}
	*/

}