
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.aggg-1-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-1-picture-background, .ready-to-load-img .aggg-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/aggg-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/aggg-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/aggg-1.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-1-picture-box-background, .ready-to-load-img .aggg-1-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-1-2x.webp");
		}
	}
}

.aggg-1-picture-background-ratio, .aggg-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-boulders-1-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-boulders-1-picture-background, .ready-to-load-img .aggg-boulders-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-boulders-1-picture-box-background, .ready-to-load-img .aggg-boulders-1-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/aggg-boulders-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-boulders-1-2x.webp");
		}
	}
}

.aggg-boulders-1-picture-background-ratio, .aggg-boulders-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-boulders-2-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-boulders-2-picture-background, .ready-to-load-img .aggg-boulders-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-boulders-2-picture-box-background, .ready-to-load-img .aggg-boulders-2-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/aggg-boulders-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-boulders-2-2x.webp");
		}
	}
}

.aggg-boulders-2-picture-background-ratio, .aggg-boulders-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-pea-rock-1-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-pea-rock-1-picture-background, .ready-to-load-img .aggg-pea-rock-1-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-pea-rock-1-picture-box-background, .ready-to-load-img .aggg-pea-rock-1-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-pea-rock-1-2x.webp");
		}
	}
}

.aggg-pea-rock-1-picture-background-ratio, .aggg-pea-rock-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-recycled-asphalt-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-recycled-asphalt-picture-background, .ready-to-load-img .aggg-recycled-asphalt-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-recycled-asphalt-picture-box-background, .ready-to-load-img .aggg-recycled-asphalt-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-recycled-asphalt-2x.webp");
		}
	}
}

.aggg-recycled-asphalt-picture-background-ratio, .aggg-recycled-asphalt-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-screened-black-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-screened-black-picture-background, .ready-to-load-img .aggg-screened-black-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/aggg-screened-black.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/aggg-screened-black.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/aggg-screened-black-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-screened-black-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/aggg-screened-black-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-screened-black-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/aggg-screened-black.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/aggg-screened-black.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-screened-black-picture-box-background, .ready-to-load-img .aggg-screened-black-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/aggg-screened-black.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/aggg-screened-black.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/aggg-screened-black-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/aggg-screened-black-2x.webp");
		}
	}
}

.aggg-screened-black-picture-background-ratio, .aggg-screened-black-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-washed-round-rock-2-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-washed-round-rock-2-picture-background, .ready-to-load-img .aggg-washed-round-rock-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-washed-round-rock-2-picture-box-background, .ready-to-load-img .aggg-washed-round-rock-2-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/aggg-washed-round-rock-2-2x.webp");
		}
	}
}

.aggg-washed-round-rock-2-picture-background-ratio, .aggg-washed-round-rock-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-washed-round-rock-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-washed-round-rock-picture-background, .ready-to-load-img .aggg-washed-round-rock-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-washed-round-rock-picture-box-background, .ready-to-load-img .aggg-washed-round-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/aggg-washed-round-rock-2x.webp");
		}
	}
}

.aggg-washed-round-rock-picture-background-ratio, .aggg-washed-round-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggg-washed-sand-picture img {
	width: 683px;
}

.ready-to-load-img .aggg-washed-sand-picture-background, .ready-to-load-img .aggg-washed-sand-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.webp");
			}
		}
	}
}
.ready-to-load-img .aggg-washed-sand-picture-box-background, .ready-to-load-img .aggg-washed-sand-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/aggg-washed-sand.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/aggg-washed-sand-2x.webp");
		}
	}
}

.aggg-washed-sand-picture-background-ratio, .aggg-washed-sand-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-01-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-01-picture-background, .ready-to-load-img .our-work-commercial-01-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-01-picture-box-background, .ready-to-load-img .our-work-commercial-01-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-01.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-01-2x.webp");
		}
	}
}

.our-work-commercial-01-picture-background-ratio, .our-work-commercial-01-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-02-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-02-picture-background, .ready-to-load-img .our-work-commercial-02-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-02-picture-box-background, .ready-to-load-img .our-work-commercial-02-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-02-2x.webp");
		}
	}
}

.our-work-commercial-02-picture-background-ratio, .our-work-commercial-02-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-03-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-03-picture-background, .ready-to-load-img .our-work-commercial-03-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-03-picture-box-background, .ready-to-load-img .our-work-commercial-03-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-03.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-03-2x.webp");
		}
	}
}

.our-work-commercial-03-picture-background-ratio, .our-work-commercial-03-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-04-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-04-picture-background, .ready-to-load-img .our-work-commercial-04-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-04-picture-box-background, .ready-to-load-img .our-work-commercial-04-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-04.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-04-2x.webp");
		}
	}
}

.our-work-commercial-04-picture-background-ratio, .our-work-commercial-04-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-05-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-05-picture-background, .ready-to-load-img .our-work-commercial-05-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-05-picture-box-background, .ready-to-load-img .our-work-commercial-05-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-05.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-05-2x.webp");
		}
	}
}

.our-work-commercial-05-picture-background-ratio, .our-work-commercial-05-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-06-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-06-picture-background, .ready-to-load-img .our-work-commercial-06-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-06-picture-box-background, .ready-to-load-img .our-work-commercial-06-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-06.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-06-2x.webp");
		}
	}
}

.our-work-commercial-06-picture-background-ratio, .our-work-commercial-06-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-07-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-07-picture-background, .ready-to-load-img .our-work-commercial-07-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-07-picture-box-background, .ready-to-load-img .our-work-commercial-07-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-07.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-07-2x.webp");
		}
	}
}

.our-work-commercial-07-picture-background-ratio, .our-work-commercial-07-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-08-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-08-picture-background, .ready-to-load-img .our-work-commercial-08-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-08-picture-box-background, .ready-to-load-img .our-work-commercial-08-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-08.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-08-2x.webp");
		}
	}
}

.our-work-commercial-08-picture-background-ratio, .our-work-commercial-08-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-09-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-09-picture-background, .ready-to-load-img .our-work-commercial-09-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-09-picture-box-background, .ready-to-load-img .our-work-commercial-09-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-09.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-09-2x.webp");
		}
	}
}

.our-work-commercial-09-picture-background-ratio, .our-work-commercial-09-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-10-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-10-picture-background, .ready-to-load-img .our-work-commercial-10-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-10-picture-box-background, .ready-to-load-img .our-work-commercial-10-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-10.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-10-2x.webp");
		}
	}
}

.our-work-commercial-10-picture-background-ratio, .our-work-commercial-10-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-11-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-11-picture-background, .ready-to-load-img .our-work-commercial-11-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-11-picture-box-background, .ready-to-load-img .our-work-commercial-11-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-11.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-11-2x.webp");
		}
	}
}

.our-work-commercial-11-picture-background-ratio, .our-work-commercial-11-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-12-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-12-picture-background, .ready-to-load-img .our-work-commercial-12-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-12-picture-box-background, .ready-to-load-img .our-work-commercial-12-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-12.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-12-2x.webp");
		}
	}
}

.our-work-commercial-12-picture-background-ratio, .our-work-commercial-12-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-13-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-13-picture-background, .ready-to-load-img .our-work-commercial-13-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-13-picture-box-background, .ready-to-load-img .our-work-commercial-13-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-13.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-13-2x.webp");
		}
	}
}

.our-work-commercial-13-picture-background-ratio, .our-work-commercial-13-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-14-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-14-picture-background, .ready-to-load-img .our-work-commercial-14-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-14-picture-box-background, .ready-to-load-img .our-work-commercial-14-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-14.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-14-2x.webp");
		}
	}
}

.our-work-commercial-14-picture-background-ratio, .our-work-commercial-14-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-15-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-15-picture-background, .ready-to-load-img .our-work-commercial-15-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-15-picture-box-background, .ready-to-load-img .our-work-commercial-15-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-15.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-15-2x.webp");
		}
	}
}

.our-work-commercial-15-picture-background-ratio, .our-work-commercial-15-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-16-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-16-picture-background, .ready-to-load-img .our-work-commercial-16-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-16-picture-box-background, .ready-to-load-img .our-work-commercial-16-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-16.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-16-2x.webp");
		}
	}
}

.our-work-commercial-16-picture-background-ratio, .our-work-commercial-16-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-17-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-17-picture-background, .ready-to-load-img .our-work-commercial-17-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-17-picture-box-background, .ready-to-load-img .our-work-commercial-17-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-17.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-17-2x.webp");
		}
	}
}

.our-work-commercial-17-picture-background-ratio, .our-work-commercial-17-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-18-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-18-picture-background, .ready-to-load-img .our-work-commercial-18-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-18-picture-box-background, .ready-to-load-img .our-work-commercial-18-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-18.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-18-2x.webp");
		}
	}
}

.our-work-commercial-18-picture-background-ratio, .our-work-commercial-18-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-19-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-19-picture-background, .ready-to-load-img .our-work-commercial-19-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-19-picture-box-background, .ready-to-load-img .our-work-commercial-19-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-19.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-commercial-19-2x.webp");
		}
	}
}

.our-work-commercial-19-picture-background-ratio, .our-work-commercial-19-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-20-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-20-picture-background, .ready-to-load-img .our-work-commercial-20-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-20-picture-box-background, .ready-to-load-img .our-work-commercial-20-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-20.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-commercial-20-2x.webp");
		}
	}
}

.our-work-commercial-20-picture-background-ratio, .our-work-commercial-20-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-21-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-21-picture-background, .ready-to-load-img .our-work-commercial-21-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-21-picture-box-background, .ready-to-load-img .our-work-commercial-21-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-21.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-commercial-21-2x.webp");
		}
	}
}

.our-work-commercial-21-picture-background-ratio, .our-work-commercial-21-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-commercial-22-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-commercial-22-picture-background, .ready-to-load-img .our-work-commercial-22-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-commercial-22-picture-box-background, .ready-to-load-img .our-work-commercial-22-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-22.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-commercial-22-2x.webp");
		}
	}
}

.our-work-commercial-22-picture-background-ratio, .our-work-commercial-22-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-01-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-01-picture-background, .ready-to-load-img .our-work-crushing-01-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-01-picture-box-background, .ready-to-load-img .our-work-crushing-01-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-01.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-01-2x.webp");
		}
	}
}

.our-work-crushing-01-picture-background-ratio, .our-work-crushing-01-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-02-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-02-picture-background, .ready-to-load-img .our-work-crushing-02-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-02-picture-box-background, .ready-to-load-img .our-work-crushing-02-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-02-2x.webp");
		}
	}
}

.our-work-crushing-02-picture-background-ratio, .our-work-crushing-02-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-03-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-03-picture-background, .ready-to-load-img .our-work-crushing-03-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-03-picture-box-background, .ready-to-load-img .our-work-crushing-03-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-03.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-03-2x.webp");
		}
	}
}

.our-work-crushing-03-picture-background-ratio, .our-work-crushing-03-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-04-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-04-picture-background, .ready-to-load-img .our-work-crushing-04-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-04-picture-box-background, .ready-to-load-img .our-work-crushing-04-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-04.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-04-2x.webp");
		}
	}
}

.our-work-crushing-04-picture-background-ratio, .our-work-crushing-04-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-05-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-05-picture-background, .ready-to-load-img .our-work-crushing-05-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-05-picture-box-background, .ready-to-load-img .our-work-crushing-05-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-05.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-05-2x.webp");
		}
	}
}

.our-work-crushing-05-picture-background-ratio, .our-work-crushing-05-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-06-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-06-picture-background, .ready-to-load-img .our-work-crushing-06-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-06-picture-box-background, .ready-to-load-img .our-work-crushing-06-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-06.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-06-2x.webp");
		}
	}
}

.our-work-crushing-06-picture-background-ratio, .our-work-crushing-06-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-07-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-07-picture-background, .ready-to-load-img .our-work-crushing-07-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-07-picture-box-background, .ready-to-load-img .our-work-crushing-07-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-07.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-07-2x.webp");
		}
	}
}

.our-work-crushing-07-picture-background-ratio, .our-work-crushing-07-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-08-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-08-picture-background, .ready-to-load-img .our-work-crushing-08-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-08-picture-box-background, .ready-to-load-img .our-work-crushing-08-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-08.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-08-2x.webp");
		}
	}
}

.our-work-crushing-08-picture-background-ratio, .our-work-crushing-08-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-09-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-09-picture-background, .ready-to-load-img .our-work-crushing-09-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-09-picture-box-background, .ready-to-load-img .our-work-crushing-09-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-09.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-09-2x.webp");
		}
	}
}

.our-work-crushing-09-picture-background-ratio, .our-work-crushing-09-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-10-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-10-picture-background, .ready-to-load-img .our-work-crushing-10-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-10-picture-box-background, .ready-to-load-img .our-work-crushing-10-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-10.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-10-2x.webp");
		}
	}
}

.our-work-crushing-10-picture-background-ratio, .our-work-crushing-10-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-11-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-11-picture-background, .ready-to-load-img .our-work-crushing-11-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-11-picture-box-background, .ready-to-load-img .our-work-crushing-11-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-11.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-crushing-11-2x.webp");
		}
	}
}

.our-work-crushing-11-picture-background-ratio, .our-work-crushing-11-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-12-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-12-picture-background, .ready-to-load-img .our-work-crushing-12-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-12-picture-box-background, .ready-to-load-img .our-work-crushing-12-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-12.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-crushing-12-2x.webp");
		}
	}
}

.our-work-crushing-12-picture-background-ratio, .our-work-crushing-12-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-13-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-13-picture-background, .ready-to-load-img .our-work-crushing-13-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-13-picture-box-background, .ready-to-load-img .our-work-crushing-13-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-13.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-crushing-13-2x.webp");
		}
	}
}

.our-work-crushing-13-picture-background-ratio, .our-work-crushing-13-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-crushing-14-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-crushing-14-picture-background, .ready-to-load-img .our-work-crushing-14-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-crushing-14-picture-box-background, .ready-to-load-img .our-work-crushing-14-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-14.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-crushing-14-2x.webp");
		}
	}
}

.our-work-crushing-14-picture-background-ratio, .our-work-crushing-14-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-header-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-header-picture-background, .ready-to-load-img .our-work-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-header.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-header.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-header-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-header-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-header.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-header.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-header-picture-box-background, .ready-to-load-img .our-work-header-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-header.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-header.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-header-2x.webp");
		}
	}
}

.our-work-header-picture-background-ratio, .our-work-header-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-01-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-01-picture-background, .ready-to-load-img .our-work-municipal-01-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-01-picture-box-background, .ready-to-load-img .our-work-municipal-01-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-01.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-01-2x.webp");
		}
	}
}

.our-work-municipal-01-picture-background-ratio, .our-work-municipal-01-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-02-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-02-picture-background, .ready-to-load-img .our-work-municipal-02-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-02-picture-box-background, .ready-to-load-img .our-work-municipal-02-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-02-2x.webp");
		}
	}
}

.our-work-municipal-02-picture-background-ratio, .our-work-municipal-02-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-03-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-03-picture-background, .ready-to-load-img .our-work-municipal-03-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-03-picture-box-background, .ready-to-load-img .our-work-municipal-03-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-03.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-03-2x.webp");
		}
	}
}

.our-work-municipal-03-picture-background-ratio, .our-work-municipal-03-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-04-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-04-picture-background, .ready-to-load-img .our-work-municipal-04-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-04-picture-box-background, .ready-to-load-img .our-work-municipal-04-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-04.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-04-2x.webp");
		}
	}
}

.our-work-municipal-04-picture-background-ratio, .our-work-municipal-04-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-05-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-05-picture-background, .ready-to-load-img .our-work-municipal-05-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-05-picture-box-background, .ready-to-load-img .our-work-municipal-05-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-05.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-05-2x.webp");
		}
	}
}

.our-work-municipal-05-picture-background-ratio, .our-work-municipal-05-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-06-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-06-picture-background, .ready-to-load-img .our-work-municipal-06-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-06-picture-box-background, .ready-to-load-img .our-work-municipal-06-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-06.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-06-2x.webp");
		}
	}
}

.our-work-municipal-06-picture-background-ratio, .our-work-municipal-06-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-07-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-07-picture-background, .ready-to-load-img .our-work-municipal-07-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-07-picture-box-background, .ready-to-load-img .our-work-municipal-07-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-07.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-07-2x.webp");
		}
	}
}

.our-work-municipal-07-picture-background-ratio, .our-work-municipal-07-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-08-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-08-picture-background, .ready-to-load-img .our-work-municipal-08-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-08-picture-box-background, .ready-to-load-img .our-work-municipal-08-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-08.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-08-2x.webp");
		}
	}
}

.our-work-municipal-08-picture-background-ratio, .our-work-municipal-08-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-09-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-09-picture-background, .ready-to-load-img .our-work-municipal-09-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-09-picture-box-background, .ready-to-load-img .our-work-municipal-09-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-09.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-09-2x.webp");
		}
	}
}

.our-work-municipal-09-picture-background-ratio, .our-work-municipal-09-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-10-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-10-picture-background, .ready-to-load-img .our-work-municipal-10-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-10-picture-box-background, .ready-to-load-img .our-work-municipal-10-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-10.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-municipal-10-2x.webp");
		}
	}
}

.our-work-municipal-10-picture-background-ratio, .our-work-municipal-10-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-11-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-11-picture-background, .ready-to-load-img .our-work-municipal-11-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-11-picture-box-background, .ready-to-load-img .our-work-municipal-11-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-11.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-municipal-11-2x.webp");
		}
	}
}

.our-work-municipal-11-picture-background-ratio, .our-work-municipal-11-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-12-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-12-picture-background, .ready-to-load-img .our-work-municipal-12-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-12-picture-box-background, .ready-to-load-img .our-work-municipal-12-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-12.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-municipal-12-2x.webp");
		}
	}
}

.our-work-municipal-12-picture-background-ratio, .our-work-municipal-12-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-municipal-13-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-municipal-13-picture-background, .ready-to-load-img .our-work-municipal-13-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-municipal-13-picture-box-background, .ready-to-load-img .our-work-municipal-13-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-13.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-municipal-13-2x.webp");
		}
	}
}

.our-work-municipal-13-picture-background-ratio, .our-work-municipal-13-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-01-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-01-picture-background, .ready-to-load-img .our-work-residential-01-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-01.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-01.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-residential-01-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-01-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-01-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-residential-01.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-residential-01.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-01-picture-box-background, .ready-to-load-img .our-work-residential-01-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-01.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-01.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-01-2x.webp");
		}
	}
}

.our-work-residential-01-picture-background-ratio, .our-work-residential-01-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-02-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-02-picture-background, .ready-to-load-img .our-work-residential-02-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-02.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-02.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-residential-02-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-02-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-02-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-residential-02.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-residential-02.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-02-picture-box-background, .ready-to-load-img .our-work-residential-02-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-02.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-02-2x.webp");
		}
	}
}

.our-work-residential-02-picture-background-ratio, .our-work-residential-02-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-03-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-03-picture-background, .ready-to-load-img .our-work-residential-03-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-03.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-03.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-residential-03-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-03-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-03-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-residential-03.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-residential-03.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-03-picture-box-background, .ready-to-load-img .our-work-residential-03-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-03.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-03.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-03-2x.webp");
		}
	}
}

.our-work-residential-03-picture-background-ratio, .our-work-residential-03-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-04-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-04-picture-background, .ready-to-load-img .our-work-residential-04-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-04.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-04.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-residential-04-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-04-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-04-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-residential-04.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-residential-04.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-04-picture-box-background, .ready-to-load-img .our-work-residential-04-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-04.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-04.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-04-2x.webp");
		}
	}
}

.our-work-residential-04-picture-background-ratio, .our-work-residential-04-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-05-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-05-picture-background, .ready-to-load-img .our-work-residential-05-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-05.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-05.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-residential-05-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-05-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-05-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-residential-05.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-residential-05.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-05-picture-box-background, .ready-to-load-img .our-work-residential-05-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-05.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-05.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-05-2x.webp");
		}
	}
}

.our-work-residential-05-picture-background-ratio, .our-work-residential-05-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-06-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-06-picture-background, .ready-to-load-img .our-work-residential-06-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-06.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-06.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-residential-06-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-06-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-06-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-residential-06.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-residential-06.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-06-picture-box-background, .ready-to-load-img .our-work-residential-06-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-06.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-06.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-06-2x.webp");
		}
	}
}

.our-work-residential-06-picture-background-ratio, .our-work-residential-06-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-07-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-07-picture-background, .ready-to-load-img .our-work-residential-07-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-07.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-07.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-residential-07-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-07-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-07-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-residential-07.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-residential-07.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-07-picture-box-background, .ready-to-load-img .our-work-residential-07-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-07.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-07.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-07-2x.webp");
		}
	}
}

.our-work-residential-07-picture-background-ratio, .our-work-residential-07-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-08-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-08-picture-background, .ready-to-load-img .our-work-residential-08-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-08.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-08.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-residential-08-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-08-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-08-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-residential-08.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-residential-08.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-08-picture-box-background, .ready-to-load-img .our-work-residential-08-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-08.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-08.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-08-2x.webp");
		}
	}
}

.our-work-residential-08-picture-background-ratio, .our-work-residential-08-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-09-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-09-picture-background, .ready-to-load-img .our-work-residential-09-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-09.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-09.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-residential-09-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-09-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-09-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-residential-09.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-residential-09.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-09-picture-box-background, .ready-to-load-img .our-work-residential-09-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-09.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-09.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-09-2x.webp");
		}
	}
}

.our-work-residential-09-picture-background-ratio, .our-work-residential-09-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-10-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-10-picture-background, .ready-to-load-img .our-work-residential-10-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-10.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-10.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-residential-10-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-10-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-10-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-residential-10.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-residential-10.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-10-picture-box-background, .ready-to-load-img .our-work-residential-10-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-10.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-10.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-10-2x.webp");
		}
	}
}

.our-work-residential-10-picture-background-ratio, .our-work-residential-10-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-11-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-11-picture-background, .ready-to-load-img .our-work-residential-11-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-11.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-11.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-residential-11-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-11-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-11-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-residential-11.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-residential-11.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-11-picture-box-background, .ready-to-load-img .our-work-residential-11-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-residential-11.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-residential-11.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-residential-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-residential-11-2x.webp");
		}
	}
}

.our-work-residential-11-picture-background-ratio, .our-work-residential-11-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-12-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-12-picture-background, .ready-to-load-img .our-work-residential-12-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-12.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-12.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-residential-12-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-12-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-12-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-residential-12.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-residential-12.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-12-picture-box-background, .ready-to-load-img .our-work-residential-12-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-residential-12.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-residential-12.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-residential-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-residential-12-2x.webp");
		}
	}
}

.our-work-residential-12-picture-background-ratio, .our-work-residential-12-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-13-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-13-picture-background, .ready-to-load-img .our-work-residential-13-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-13.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-13.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-residential-13-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-13-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-13-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-residential-13.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-residential-13.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-13-picture-box-background, .ready-to-load-img .our-work-residential-13-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-residential-13.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-residential-13.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-residential-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-residential-13-2x.webp");
		}
	}
}

.our-work-residential-13-picture-background-ratio, .our-work-residential-13-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-residential-14-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-residential-14-picture-background, .ready-to-load-img .our-work-residential-14-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-14.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-14.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-residential-14-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-14-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-14-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-residential-14.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-residential-14.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-residential-14-picture-box-background, .ready-to-load-img .our-work-residential-14-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-residential-14.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-residential-14.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-residential-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-residential-14-2x.webp");
		}
	}
}

.our-work-residential-14-picture-background-ratio, .our-work-residential-14-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-01-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-01-picture-background, .ready-to-load-img .our-work-trucking-01-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-01-picture-box-background, .ready-to-load-img .our-work-trucking-01-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-01.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-01-2x.webp");
		}
	}
}

.our-work-trucking-01-picture-background-ratio, .our-work-trucking-01-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-02-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-02-picture-background, .ready-to-load-img .our-work-trucking-02-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-02-picture-box-background, .ready-to-load-img .our-work-trucking-02-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-02-2x.webp");
		}
	}
}

.our-work-trucking-02-picture-background-ratio, .our-work-trucking-02-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-03-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-03-picture-background, .ready-to-load-img .our-work-trucking-03-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-03-picture-box-background, .ready-to-load-img .our-work-trucking-03-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-03.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-03-2x.webp");
		}
	}
}

.our-work-trucking-03-picture-background-ratio, .our-work-trucking-03-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-04-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-04-picture-background, .ready-to-load-img .our-work-trucking-04-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-04-picture-box-background, .ready-to-load-img .our-work-trucking-04-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-04.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-04-2x.webp");
		}
	}
}

.our-work-trucking-04-picture-background-ratio, .our-work-trucking-04-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-05-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-05-picture-background, .ready-to-load-img .our-work-trucking-05-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-05-picture-box-background, .ready-to-load-img .our-work-trucking-05-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-05.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-05-2x.webp");
		}
	}
}

.our-work-trucking-05-picture-background-ratio, .our-work-trucking-05-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-06-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-06-picture-background, .ready-to-load-img .our-work-trucking-06-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-06-picture-box-background, .ready-to-load-img .our-work-trucking-06-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-06.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-06-2x.webp");
		}
	}
}

.our-work-trucking-06-picture-background-ratio, .our-work-trucking-06-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-07-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-07-picture-background, .ready-to-load-img .our-work-trucking-07-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-07-picture-box-background, .ready-to-load-img .our-work-trucking-07-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-07.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-07-2x.webp");
		}
	}
}

.our-work-trucking-07-picture-background-ratio, .our-work-trucking-07-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-08-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-08-picture-background, .ready-to-load-img .our-work-trucking-08-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-08-picture-box-background, .ready-to-load-img .our-work-trucking-08-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-08.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-08-2x.webp");
		}
	}
}

.our-work-trucking-08-picture-background-ratio, .our-work-trucking-08-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-09-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-09-picture-background, .ready-to-load-img .our-work-trucking-09-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-09-picture-box-background, .ready-to-load-img .our-work-trucking-09-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-09.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-09-2x.webp");
		}
	}
}

.our-work-trucking-09-picture-background-ratio, .our-work-trucking-09-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-10-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-10-picture-background, .ready-to-load-img .our-work-trucking-10-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-10-picture-box-background, .ready-to-load-img .our-work-trucking-10-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-10.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-10-2x.webp");
		}
	}
}

.our-work-trucking-10-picture-background-ratio, .our-work-trucking-10-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-11-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-11-picture-background, .ready-to-load-img .our-work-trucking-11-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-11-picture-box-background, .ready-to-load-img .our-work-trucking-11-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-11.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-11-2x.webp");
		}
	}
}

.our-work-trucking-11-picture-background-ratio, .our-work-trucking-11-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-12-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-12-picture-background, .ready-to-load-img .our-work-trucking-12-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-12-picture-box-background, .ready-to-load-img .our-work-trucking-12-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-12.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-12-2x.webp");
		}
	}
}

.our-work-trucking-12-picture-background-ratio, .our-work-trucking-12-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-13-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-13-picture-background, .ready-to-load-img .our-work-trucking-13-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-13-picture-box-background, .ready-to-load-img .our-work-trucking-13-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-13.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-13-2x.webp");
		}
	}
}

.our-work-trucking-13-picture-background-ratio, .our-work-trucking-13-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-14-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-14-picture-background, .ready-to-load-img .our-work-trucking-14-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-14-picture-box-background, .ready-to-load-img .our-work-trucking-14-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-14.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/our-work/our-work-trucking-14-2x.webp");
		}
	}
}

.our-work-trucking-14-picture-background-ratio, .our-work-trucking-14-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-15-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-15-picture-background, .ready-to-load-img .our-work-trucking-15-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-15-picture-box-background, .ready-to-load-img .our-work-trucking-15-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-15.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/our-work/our-work-trucking-15-2x.webp");
		}
	}
}

.our-work-trucking-15-picture-background-ratio, .our-work-trucking-15-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-16-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-16-picture-background, .ready-to-load-img .our-work-trucking-16-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-16-picture-box-background, .ready-to-load-img .our-work-trucking-16-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-16.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/our-work/our-work-trucking-16-2x.webp");
		}
	}
}

.our-work-trucking-16-picture-background-ratio, .our-work-trucking-16-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.our-work-trucking-17-picture img {
	width: 683px;
}

.ready-to-load-img .our-work-trucking-17-picture-background, .ready-to-load-img .our-work-trucking-17-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.webp");
			}
		}
	}
}
.ready-to-load-img .our-work-trucking-17-picture-box-background, .ready-to-load-img .our-work-trucking-17-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-17.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/our-work/our-work-trucking-17-2x.webp");
		}
	}
}

.our-work-trucking-17-picture-background-ratio, .our-work-trucking-17-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
