

.std-header {
	color: @grey-dark;
	background-color: white;
	margin-bottom: 40px;

	.tablet({
		padding: 20px @outside-page-padding 20px @outside-page-padding;

	});
	
	.logo {
		color: white;
		text-decoration: none;
		img {
			// width: 100%;
			// max-width: 600px;
			max-width: 85%;
		}
		font-size: 0;
	}
	
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a, .link-sub {
				.fs(20);
				color: @grey-dark;	
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
		
		.phone-number {
			a {
				color: @green-main !important;
			}
			padding-top: 20px;
		}
	}

	.desktop({
		display: grid;
		grid-template-columns: auto 1fr;
	
		nav {
			// justify-self: right;
			// align-self: center;
			// text-align: right;

		}
		.menu {
			// display: inline-block;
			position: relative;
			margin: 0;
			padding: 0;
			padding-right: 50px;
			display: inline-block;

			& > li {
				
				&.current {
					& > a {
						color: @grey-dark;
						font-style: italic;
					}
				}
				
				&.secondary-menu {
					ul {
						position: absolute;
						margin: 0;
						// padding: 20px;
						top: 0;
						left: -10000px;
						opacity: 0;
						transition: opacity .3s;
						background-color: white;
						text-align: left;
						list-style: none;
						font-size: 60%;
						width: 300px;
						li {
							padding: 0;
							margin: 10px 0;
							
							&:first-child {
								margin-top: 0;
							}
							
						}
					}
					
					&:hover {
						ul {
							left: auto;
							right: 0;
							opacity: 1;
						}
					}
				}

				&.what-we-do {
					position: absolute;
					left: 100%;
					top: 0;
					ul {
						list-style: none;
						margin: 10px 0 0 0;
						padding: 0;
						
						li {
							margin-left: 0;
						}

						a {
							white-space: nowrap;
							color: @tan-dark;
						}
						li.current {
							a {
								font-style: italic;
							}
						}
					}
				}
				
			}
		}
		.tribar {
			display: none;
		}
	});
	.desktop-and-smaller({
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
				li {
					a, .link-sub {
						display: inline-block;
						color: white;
					}
					ul {
						list-style: none;
						margin-top: 10px;
						margin-left: 0;
						padding-left: 0;
					}
				}
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
					font-weight: 600;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: black;
			}
			.two {
				margin: 7px 0;
			}
			
		}
	});

}

header {
	.std-header;
}

.page-landing {
	.page-header{
		aspect-ratio: 4;
	}
}

.page-inner {
	.page-header {
		.container {
			.ready-to-load-img.sub-pages-header-picture-box-background();
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			min-height: 315px;
			
			display: flex;
			align-items: center;
			
			h1 {
				.phudu(60);
				.tablet-and-smaller({
					font-size: 5vw;
					font-size: clamp(25pt, 6vw, 45pt);

				});
			}
		}
	}
	
}

.std-txt {
	h1, h2, h3, h4, h5, h6 {
		color: @green-medium;
		&:first-child {
			margin-top: 0;
		}
	}

	h3, h4, h5, h6 {
		text-transform: none;
	}
	h1 {
		.phudu(60);
	}
	h3 {
		.raleway(22);
	}
	
	p {
		.raleway(20);
		line-height: 1.4em;
		&:first-child {
			margin-top: 0;
		}
	}
	
	.tan-cta {
		.raleway(22);
		color: @tan-dark;
		font-style: italic;
		font-weight: 600;
		
		.same {
			color: inherit;
			text-decoration: inherit;
		}
	}
	
	ul {
		columns: 2;
		
		&.one-col {
			columns: 1;
		}
		column-gap: 30px;
		padding-left: 20px;
		li {
			.raleway(20);
			line-height: 1.6em;
		}
		.big-phone-and-smaller({
			columns: 1;
		})
	}
	
	.tablet-and-smaller({
		h1 {
			font-size: 5vw;
			font-size: clamp(21pt, 5vw, 45pt);
		}
	});
}



footer {
	margin-top: 60px;
	padding-top: 20px;
	padding-bottom: 30px;
	.brand {
		grid-column: 3 / span 3;
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: solid 2px @green-main;

		img {
			width: 443px;
			max-width: 100%;
		}
	}
	h4 {
		.raleway(16);
		font-weight: 600;
		color: @green-main;
		margin-bottom: 0;
		
		&:first-child {
			margin-top: 0;
		}
	}
	.container-left, .container-right {
		line-height: 1.8em;
		.tablet({
			display: grid;
			grid-template-columns: 3fr 2fr;
			column-gap: 30px;
		});
	}
	
	
	.container-left {
		p {
			.raleway(16);
		}
		h4 + p {
			margin-top: 0;
		}
		.box-links {
			a {
				.raleway(16);
				font-weight: 600;
				color: @green-main;
			}
		}
	}
	.container-right {
		.social-icons {
			margin-top: 30px;
		}
	}
	.address {
		font-weight: 600;
		line-height: 1.8em;
		color: @green-main;
		a {
			color: inherit;
		}
	}
	.pits {
		a {
			color: @tan-dark;
		}
	}
	
	svg {
		height: 30px;
		width: 40px;
	}
	.social-icons {
		a {
			text-decoration: none;
		}
		
		.facebook {
			fill: @blue-facebook;
		}
		.linkedin {
			fill: @blue-linkedin;
		}
		
		.instagram {
			padding-left: 8px;
		}
	}
	.quick {
		a {
			color: @tan-dark;
		}
	}
}

.svg-icons {
	position: absolute;
	left: -20000px;
}

.about-header-square {
	.ready-to-load-img.about-us-header-square-picture-box-background();
}

.full-cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

div.aggregate-calculator-section {
	.display {
		display: grid;
		column-gap: 20px;
		row-gap: 20px;
		.big-phone({
			grid-template-columns: auto minmax(250px, 1fr);
		})
	}
	.pic {
		text-align: center;
		img {
			max-width: 100%;

		}
	}
	
}

form.aggregate-calculator {
	.msg {
		margin-bottom: 30px;
		color: red;
	}
	.button {
		text-align: center;
		margin-bottom: 20px;
	}
	.measurement {
		display: grid;
		grid-template-columns: repeat(2, auto);
		column-gap: 15px;
		padding: 0 20px;
		label {
			display: contents;
			&:after {
				content: '\00a0';
				display: block;
				grid-column: span 2;
				height: 14px;
			}
		}
		.label {
			grid-column: span 2;
			padding-bottom: 5px;
		}

	}
	
	.result {
		visibility: hidden;
		text-align: center;
		font-weight: 600;
		.fs(20);
		
		&.show {
			visibility: visible;
		}
	}
	.call-to-action {
		margin-top: 40px;
		text-align: center;
	}
	
}


.application-form {

	.contents {
		display: contents !important;
	}
	& > *:not(.current) {
		display: none !important;
	}
	
	& > * > * {
		margin-bottom: 60px;
	}
	
	label.inline {
		display: inline-block;
		margin-right: 20px;
	}
	
	.employment-history-position, .drivers-license {
		padding: 20px;
		background-color: #eeeeee;
		border-radius: 3px;
		margin-bottom: 50px;
	}
	
	.tablet({
		.tablet-only {
			display: block;
		}
		.tablet-no-show {
			display: none;
		}
	});
	
	.tablet-and-smaller({
		.tablet-only {
			display: none;
		}
		.tablet-no-show {
			display: block;
		}
	});
}

#form-header {
	display: flex;
	margin-bottom: 40px;
	overflow: auto;

	& > * {
		.fs(16);
		
		margin-right: 20px;
		cursor: pointer;
		
		&.current {
			color: @green-main;
			font-weight: 600;
		}
	}
}

#form-footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 50px;
	.previous {
		grid-column: 1;
		justify-self: start;
	}

	.next {
		grid-column: 2;
		justify-self: end;
	}

	.submit {
		grid-column: 2;
		justify-self: end;
	}

	&.start {
		.previous {
			display: none;
		}
		.submit {
			display: none;
		}
	}
	
	&.mid {
		.submit {
			display: none;
		}
	}
	
	&.end {
		.next {
			display: none;
		}
	}
}

.apply-buttons {
	text-align: center;
	.green-button {
		display: inline-block;
		box-sizing: border-box;
		max-width: 100%;
		width: 400px;
		margin-right: 10px;
		margin-left: 10px;
		margin-bottom: 20px;

	}
}


@import "pages/residential";
@import "pages/home";
@import "pages/our-work";
@import "pages/custom-crushing";
@import "pages/commercial";
@import "pages/municipal";
@import "pages/trucking";
@import "pages/aggregates";
@import "pages/about";
@import "pages/contact";
@import "pages/employment";