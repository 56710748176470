
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.comm-utility-install-picture img {
	width: 683px;
}

.ready-to-load-img .comm-utility-install-picture-background, .ready-to-load-img .comm-utility-install-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/commercial/comm-utility-install.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/commercial/comm-utility-install.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/commercial/comm-utility-install-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/comm-utility-install-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/commercial/comm-utility-install-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/comm-utility-install-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/commercial/comm-utility-install.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/commercial/comm-utility-install.webp");
			}
		}
	}
}
.ready-to-load-img .comm-utility-install-picture-box-background, .ready-to-load-img .comm-utility-install-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/commercial/comm-utility-install.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/commercial/comm-utility-install.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/commercial/comm-utility-install-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/comm-utility-install-2x.webp");
		}
	}
}

.comm-utility-install-picture-background-ratio, .comm-utility-install-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.commercial-grading-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-grading-picture-background, .ready-to-load-img .commercial-grading-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/commercial/commercial-grading.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/commercial/commercial-grading.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/commercial/commercial-grading-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/commercial-grading-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/commercial/commercial-grading-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/commercial-grading-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/commercial/commercial-grading.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/commercial/commercial-grading.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-grading-picture-box-background, .ready-to-load-img .commercial-grading-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/commercial/commercial-grading.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/commercial/commercial-grading.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/commercial/commercial-grading-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/commercial-grading-2x.webp");
		}
	}
}

.commercial-grading-picture-background-ratio, .commercial-grading-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.commercial-rock-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-rock-picture-background, .ready-to-load-img .commercial-rock-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/commercial/commercial-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/commercial/commercial-rock.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/commercial/commercial-rock-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/commercial-rock-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/commercial/commercial-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/commercial-rock-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/commercial/commercial-rock.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/commercial/commercial-rock.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-rock-picture-box-background, .ready-to-load-img .commercial-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/commercial/commercial-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/commercial/commercial-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/commercial/commercial-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/commercial-rock-2x.webp");
		}
	}
}

.commercial-rock-picture-background-ratio, .commercial-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.commercial-septic-maintenance-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-septic-maintenance-picture-background, .ready-to-load-img .commercial-septic-maintenance-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-septic-maintenance-picture-box-background, .ready-to-load-img .commercial-septic-maintenance-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/commercial/commercial-septic-maintenance-2x.webp");
		}
	}
}

.commercial-septic-maintenance-picture-background-ratio, .commercial-septic-maintenance-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.commercial-utility-picture img {
	width: 683px;
}

.ready-to-load-img .commercial-utility-picture-background, .ready-to-load-img .commercial-utility-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/commercial/commercial-utility.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/commercial/commercial-utility.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/commercial/commercial-utility-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/commercial-utility-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/commercial/commercial-utility-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/commercial-utility-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/commercial/commercial-utility.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/commercial/commercial-utility.webp");
			}
		}
	}
}
.ready-to-load-img .commercial-utility-picture-box-background, .ready-to-load-img .commercial-utility-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/commercial/commercial-utility.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/commercial/commercial-utility.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/commercial/commercial-utility-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/commercial/commercial-utility-2x.webp");
		}
	}
}

.commercial-utility-picture-background-ratio, .commercial-utility-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.demo-commercial-1-picture img {
	width: 683px;
}

.ready-to-load-img .demo-commercial-1-picture-background, .ready-to-load-img .demo-commercial-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/commercial/demo-commercial-1.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/commercial/demo-commercial-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/commercial/demo-commercial-1-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/demo-commercial-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/commercial/demo-commercial-1-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/demo-commercial-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/commercial/demo-commercial-1.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/commercial/demo-commercial-1.webp");
			}
		}
	}
}
.ready-to-load-img .demo-commercial-1-picture-box-background, .ready-to-load-img .demo-commercial-1-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/commercial/demo-commercial-1.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/commercial/demo-commercial-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/commercial/demo-commercial-1-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/commercial/demo-commercial-1-2x.webp");
		}
	}
}

.demo-commercial-1-picture-background-ratio, .demo-commercial-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.site-grading-1-picture img {
	width: 683px;
}

.ready-to-load-img .site-grading-1-picture-background, .ready-to-load-img .site-grading-1-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/commercial/site-grading-1.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/commercial/site-grading-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/commercial/site-grading-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/site-grading-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/commercial/site-grading-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/site-grading-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/commercial/site-grading-1.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/commercial/site-grading-1.webp");
			}
		}
	}
}
.ready-to-load-img .site-grading-1-picture-box-background, .ready-to-load-img .site-grading-1-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/commercial/site-grading-1.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/commercial/site-grading-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/commercial/site-grading-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/commercial/site-grading-1-2x.webp");
		}
	}
}

.site-grading-1-picture-background-ratio, .site-grading-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
