
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.aggregates-boulders-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-boulders-picture-background, .ready-to-load-img .aggregates-boulders-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-boulders.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-boulders.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-boulders-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-boulders-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-boulders-picture-box-background, .ready-to-load-img .aggregates-boulders-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-boulders.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-boulders.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-boulders-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-boulders-2x.webp");
		}
	}
}

.aggregates-boulders-picture-background-ratio, .aggregates-boulders-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-concrete-class-5-picture img {
	width: 341.5px;
}

.ready-to-load-img .aggregates-concrete-class-5-picture-background, .ready-to-load-img .aggregates-concrete-class-5-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-concrete-class-5-picture-box-background, .ready-to-load-img .aggregates-concrete-class-5-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-concrete-class-5-2x.webp");
		}
	}
}

.aggregates-concrete-class-5-picture-background-ratio, .aggregates-concrete-class-5-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-crushed-rock-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-crushed-rock-picture-background, .ready-to-load-img .aggregates-crushed-rock-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-crushed-rock-picture-box-background, .ready-to-load-img .aggregates-crushed-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-crushed-rock-2x.webp");
		}
	}
}

.aggregates-crushed-rock-picture-background-ratio, .aggregates-crushed-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-granite-class-2-picture img {
	width: 341.5px;
}

.ready-to-load-img .aggregates-granite-class-2-picture-background, .ready-to-load-img .aggregates-granite-class-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-granite-class-2-picture-box-background, .ready-to-load-img .aggregates-granite-class-2-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-granite-class-2-2x.webp");
		}
	}
}

.aggregates-granite-class-2-picture-background-ratio, .aggregates-granite-class-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-gravel-class-5-picture img {
	width: 341.5px;
}

.ready-to-load-img .aggregates-gravel-class-5-picture-background, .ready-to-load-img .aggregates-gravel-class-5-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-gravel-class-5-picture-box-background, .ready-to-load-img .aggregates-gravel-class-5-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-gravel-class-5-2x.webp");
		}
	}
}

.aggregates-gravel-class-5-picture-background-ratio, .aggregates-gravel-class-5-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-onsite-crushing-picture img {
	width: 683px;
}

.ready-to-load-img .aggregates-onsite-crushing-picture-background, .ready-to-load-img .aggregates-onsite-crushing-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.webp");
			}
		}
	}
}
.ready-to-load-img .aggregates-onsite-crushing-picture-box-background, .ready-to-load-img .aggregates-onsite-crushing-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-onsite-crushing-2x.webp");
		}
	}
}

.aggregates-onsite-crushing-picture-background-ratio, .aggregates-onsite-crushing-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-pea-rock-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-pea-rock-picture-background, .ready-to-load-img .aggregates-pea-rock-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-pea-rock-picture-box-background, .ready-to-load-img .aggregates-pea-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-pea-rock-2x.webp");
		}
	}
}

.aggregates-pea-rock-picture-background-ratio, .aggregates-pea-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-roof-rock-big-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-roof-rock-big-picture-background, .ready-to-load-img .aggregates-roof-rock-big-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-roof-rock-big-picture-box-background, .ready-to-load-img .aggregates-roof-rock-big-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-roof-rock-big-2x.webp");
		}
	}
}

.aggregates-roof-rock-big-picture-background-ratio, .aggregates-roof-rock-big-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-roof-rock-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-roof-rock-picture-background, .ready-to-load-img .aggregates-roof-rock-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-roof-rock-picture-box-background, .ready-to-load-img .aggregates-roof-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/aggregates-roof-rock-2x.webp");
		}
	}
}

.aggregates-roof-rock-picture-background-ratio, .aggregates-roof-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-screened-black-dirt-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-screened-black-dirt-picture-background, .ready-to-load-img .aggregates-screened-black-dirt-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-screened-black-dirt-picture-box-background, .ready-to-load-img .aggregates-screened-black-dirt-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/aggregates/aggregates-screened-black-dirt-2x.webp");
		}
	}
}

.aggregates-screened-black-dirt-picture-background-ratio, .aggregates-screened-black-dirt-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-washed-rock-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-washed-rock-picture-background, .ready-to-load-img .aggregates-washed-rock-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-washed-rock-picture-box-background, .ready-to-load-img .aggregates-washed-rock-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/aggregates/aggregates-washed-rock-2x.webp");
		}
	}
}

.aggregates-washed-rock-picture-background-ratio, .aggregates-washed-rock-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.aggregates-washed-sand-picture img {
	width: 170px;
}

.ready-to-load-img .aggregates-washed-sand-picture-background, .ready-to-load-img .aggregates-washed-sand-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand-2x.webp");
		}
	}
}
.ready-to-load-img .aggregates-washed-sand-picture-box-background, .ready-to-load-img .aggregates-washed-sand-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/aggregates/aggregates-washed-sand-2x.webp");
		}
	}
}

.aggregates-washed-sand-picture-background-ratio, .aggregates-washed-sand-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.calculator-v02-picture img {
	width: 186.5px;
}

.ready-to-load-img .calculator-v02-picture-background, .ready-to-load-img .calculator-v02-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/calculator-v02.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/calculator-v02.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/calculator-v02-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/calculator-v02-2x.webp");
		}
	}
}
.ready-to-load-img .calculator-v02-picture-box-background, .ready-to-load-img .calculator-v02-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/aggregates/calculator-v02.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/aggregates/calculator-v02.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/aggregates/calculator-v02-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/aggregates/calculator-v02-2x.webp");
		}
	}
}

.calculator-v02-picture-background-ratio, .calculator-v02-picture-background-with-ratio {
	.ratio(1.3109919571045576);
	background-position: center center;
	background-size: cover;
}
