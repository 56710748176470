
.setup-width-definitions() {
	@container-width:			1100px;
	@tablet-width: 				900px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding: 			10px;
	@outside-page-padding: 			58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			7.6pt;

@grey-body-text:			#444444;


@tan-light: #f2cc8f;
@tan-dark: #a68f6a;

@green-light: #4bbf75;
@green-main: #4f735c;
@green-medium:@green-main;
@green-tan-box: rgb(44,64,51);
@green-dark: #2c4033;

@purple: #dbbff2;

@grey-light: #d3d3d3;
@grey-medium: #7d7d7d;
@grey-dark: #3d3d3d;

@white: #fcfcfc;

@blue-linkedin: 		#2e6bae;
@blue-facebook: 		#247eed;

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}

.phudu {
	// font-family: 'Phudu', cursive;
	font-family: 'Arimo', sans-serif;
	text-transform: uppercase;
}
.phudu(@fontSize) {
	// font-family: 'Phudu', cursive;
	font-family: 'Arimo', sans-serif;
	font-size: @fontSize * 0.75pt;
}
.raleway {
	// font-family: 'Raleway', sans-serif;
	font-family: 'Arimo', sans-serif;
}
.raleway(@fontSize) {
	font-family: 'Arimo', sans-serif;
	font-size: @fontSize * 0.75pt;
	// font-family: 'Raleway', sans-serif;
	// font-size: @fontSize * 0.74pt;
}


body {
	.raleway;
    color: @grey-dark;
}

h1, h2 {
	.link-inherit;
	.phudu;
	font-weight: 600;
}

