
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.KTE-logo-picture img {
	width: 1446.5px;
}

.ready-to-load-img .KTE-logo-picture-background, .ready-to-load-img .KTE-logo-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/KTE-logo.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/KTE-logo.webp");
	}
	
	
	@media (max-width: 723.25px) {

		background-image: url("@{cdn0}/img/KTE-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/KTE-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/KTE-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/KTE-logo-2x.webp");
		}
		@media (max-width: 723.25px) {

			background-image: url("@{cdn0}/img/KTE-logo.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/KTE-logo.webp");
			}
		}
	}
}
.ready-to-load-img .KTE-logo-picture-box-background, .ready-to-load-img .KTE-logo-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/KTE-logo.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/KTE-logo.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/KTE-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/KTE-logo-2x.webp");
		}
	}
}

.KTE-logo-picture-background-ratio, .KTE-logo-picture-background-with-ratio {
	.ratio(0.3377117179398548);
	background-position: center center;
	background-size: cover;
}

.about-us-header-square-picture img {
	width: 683px;
}

.ready-to-load-img .about-us-header-square-picture-background, .ready-to-load-img .about-us-header-square-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/about-us-header-square.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/about-us-header-square.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/about-us-header-square-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-us-header-square-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/about-us-header-square-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-us-header-square-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/about-us-header-square.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/about-us-header-square.webp");
			}
		}
	}
}
.ready-to-load-img .about-us-header-square-picture-box-background, .ready-to-load-img .about-us-header-square-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/about-us-header-square.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/about-us-header-square.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/about-us-header-square-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-us-header-square-2x.webp");
		}
	}
}

.about-us-header-square-picture-background-ratio, .about-us-header-square-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.employment-3-picture img {
	width: 683px;
}

.ready-to-load-img .employment-3-picture-background, .ready-to-load-img .employment-3-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/employment-3.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/employment-3.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/employment-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/employment-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment-3-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/employment-3.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/employment-3.webp");
			}
		}
	}
}
.ready-to-load-img .employment-3-picture-box-background, .ready-to-load-img .employment-3-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/employment-3.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/employment-3.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/employment-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/employment-3-2x.webp");
		}
	}
}

.employment-3-picture-background-ratio, .employment-3-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.foundation-picture img {
	width: 683px;
}

.ready-to-load-img .foundation-picture-background, .ready-to-load-img .foundation-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/foundation.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/foundation.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/foundation-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/foundation-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/foundation-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/foundation-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/foundation.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/foundation.webp");
			}
		}
	}
}
.ready-to-load-img .foundation-picture-box-background, .ready-to-load-img .foundation-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/foundation.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/foundation.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/foundation-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/foundation-2x.webp");
		}
	}
}

.foundation-picture-background-ratio, .foundation-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.header-residential-picture img {
	width: 2049px;
}

.ready-to-load-img .header-residential-picture-background, .ready-to-load-img .header-residential-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/header-residential.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/header-residential.webp");
	}
	
	
	@media (max-width: 1024.5px) {

		background-image: url("@{cdn0}/img/header-residential-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/header-residential-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/header-residential-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/header-residential-2x.webp");
		}
		@media (max-width: 1024.5px) {

			background-image: url("@{cdn0}/img/header-residential.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/header-residential.webp");
			}
		}
	}
}
.ready-to-load-img .header-residential-picture-box-background, .ready-to-load-img .header-residential-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/header-residential.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/header-residential.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/header-residential-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/header-residential-2x.webp");
		}
	}
}

.header-residential-picture-background-ratio, .header-residential-picture-background-with-ratio {
	.ratio(0.23060029282576866);
	background-position: center center;
	background-size: cover;
}

.instagram-logo-picture img {
	width: 30px;
}

.ready-to-load-img .instagram-logo-picture-background, .ready-to-load-img .instagram-logo-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/instagram-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/instagram-logo.webp");
	}
	
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/instagram-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/instagram-logo-2x.webp");
		}
	}
}
.ready-to-load-img .instagram-logo-picture-box-background, .ready-to-load-img .instagram-logo-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/instagram-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/instagram-logo.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/instagram-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/instagram-logo-2x.webp");
		}
	}
}

.instagram-logo-picture-background-ratio, .instagram-logo-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.kte-tan-background-picture img {
	width: 1366px;
}

.ready-to-load-img .kte-tan-background-picture-background, .ready-to-load-img .kte-tan-background-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/kte-tan-background.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/kte-tan-background.webp");
	}
	
	
	@media (max-width: 683px) {

		background-image: url("@{cdn2}/img/kte-tan-background-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/kte-tan-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/kte-tan-background-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/kte-tan-background-2x.webp");
		}
		@media (max-width: 683px) {

			background-image: url("@{cdn2}/img/kte-tan-background.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/kte-tan-background.webp");
			}
		}
	}
}
.ready-to-load-img .kte-tan-background-picture-box-background, .ready-to-load-img .kte-tan-background-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/kte-tan-background.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/kte-tan-background.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/kte-tan-background-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/kte-tan-background-2x.webp");
		}
	}
}

.kte-tan-background-picture-background-ratio, .kte-tan-background-picture-background-with-ratio {
	.ratio(0.5);
	background-position: center center;
	background-size: cover;
}

.municipal-2-picture img {
	width: 683px;
}

.ready-to-load-img .municipal-2-picture-background, .ready-to-load-img .municipal-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/municipal-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/municipal-2.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/municipal-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/municipal-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal-2-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/municipal-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/municipal-2.webp");
			}
		}
	}
}
.ready-to-load-img .municipal-2-picture-box-background, .ready-to-load-img .municipal-2-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/municipal-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/municipal-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/municipal-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal-2-2x.webp");
		}
	}
}

.municipal-2-picture-background-ratio, .municipal-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.pit-map-locations-picture img {
	width: 683px;
}

.ready-to-load-img .pit-map-locations-picture-background, .ready-to-load-img .pit-map-locations-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/pit-map-locations.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/pit-map-locations.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/pit-map-locations-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/pit-map-locations-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/pit-map-locations-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/pit-map-locations-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/pit-map-locations.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/pit-map-locations.webp");
			}
		}
	}
}
.ready-to-load-img .pit-map-locations-picture-box-background, .ready-to-load-img .pit-map-locations-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/pit-map-locations.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/pit-map-locations.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/pit-map-locations-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/pit-map-locations-2x.webp");
		}
	}
}

.pit-map-locations-picture-background-ratio, .pit-map-locations-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.septic-design-install-picture img {
	width: 683px;
}

.ready-to-load-img .septic-design-install-picture-background, .ready-to-load-img .septic-design-install-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/septic-design-install.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/septic-design-install.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/septic-design-install-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/septic-design-install-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/septic-design-install-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/septic-design-install-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/septic-design-install.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/septic-design-install.webp");
			}
		}
	}
}
.ready-to-load-img .septic-design-install-picture-box-background, .ready-to-load-img .septic-design-install-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/septic-design-install.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/septic-design-install.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/septic-design-install-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/septic-design-install-2x.webp");
		}
	}
}

.septic-design-install-picture-background-ratio, .septic-design-install-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.septic-maintenance-picture img {
	width: 683px;
}

.ready-to-load-img .septic-maintenance-picture-background, .ready-to-load-img .septic-maintenance-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/septic-maintenance.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/septic-maintenance.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/septic-maintenance-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/septic-maintenance-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/septic-maintenance-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/septic-maintenance-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/septic-maintenance.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/septic-maintenance.webp");
			}
		}
	}
}
.ready-to-load-img .septic-maintenance-picture-box-background, .ready-to-load-img .septic-maintenance-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/septic-maintenance.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/septic-maintenance.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/septic-maintenance-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/septic-maintenance-2x.webp");
		}
	}
}

.septic-maintenance-picture-background-ratio, .septic-maintenance-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.shoreline-picture img {
	width: 683px;
}

.ready-to-load-img .shoreline-picture-background, .ready-to-load-img .shoreline-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/shoreline.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/shoreline.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/shoreline-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/shoreline-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/shoreline-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/shoreline-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/shoreline.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/shoreline.webp");
			}
		}
	}
}
.ready-to-load-img .shoreline-picture-box-background, .ready-to-load-img .shoreline-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/shoreline.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/shoreline.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/shoreline-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/shoreline-2x.webp");
		}
	}
}

.shoreline-picture-background-ratio, .shoreline-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.site-grading-picture img {
	width: 683px;
}

.ready-to-load-img .site-grading-picture-background, .ready-to-load-img .site-grading-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/site-grading.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/site-grading.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/site-grading-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/site-grading-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/site-grading-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/site-grading-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/site-grading.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/site-grading.webp");
			}
		}
	}
}
.ready-to-load-img .site-grading-picture-box-background, .ready-to-load-img .site-grading-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/site-grading.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/site-grading.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/site-grading-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/site-grading-2x.webp");
		}
	}
}

.site-grading-picture-background-ratio, .site-grading-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.sub-pages-header-picture img {
	width: 1366px;
}

.ready-to-load-img .sub-pages-header-picture-background, .ready-to-load-img .sub-pages-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/sub-pages-header.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/sub-pages-header.webp");
	}
	
	
	@media (max-width: 683px) {

		background-image: url("@{cdn1}/img/sub-pages-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sub-pages-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/sub-pages-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sub-pages-header-2x.webp");
		}
		@media (max-width: 683px) {

			background-image: url("@{cdn1}/img/sub-pages-header.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/sub-pages-header.webp");
			}
		}
	}
}
.ready-to-load-img .sub-pages-header-picture-box-background, .ready-to-load-img .sub-pages-header-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/sub-pages-header.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/sub-pages-header.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/sub-pages-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/sub-pages-header-2x.webp");
		}
	}
}

.sub-pages-header-picture-background-ratio, .sub-pages-header-picture-background-with-ratio {
	.ratio(0.23060029282576866);
	background-position: center center;
	background-size: cover;
}

.testimonial-green-picture img {
	width: 683px;
}

.ready-to-load-img .testimonial-green-picture-background, .ready-to-load-img .testimonial-green-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/testimonial-green.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/testimonial-green.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/testimonial-green-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/testimonial-green-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/testimonial-green-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/testimonial-green-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/testimonial-green.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/testimonial-green.webp");
			}
		}
	}
}
.ready-to-load-img .testimonial-green-picture-box-background, .ready-to-load-img .testimonial-green-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/testimonial-green.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/testimonial-green.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/testimonial-green-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/testimonial-green-2x.webp");
		}
	}
}

.testimonial-green-picture-background-ratio, .testimonial-green-picture-background-with-ratio {
	.ratio(0.5);
	background-position: center center;
	background-size: cover;
}

.truck-contract-2-picture img {
	width: 683px;
}

.ready-to-load-img .truck-contract-2-picture-background, .ready-to-load-img .truck-contract-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/truck-contract-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/truck-contract-2.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/truck-contract-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/truck-contract-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/truck-contract-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/truck-contract-2-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/truck-contract-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/truck-contract-2.webp");
			}
		}
	}
}
.ready-to-load-img .truck-contract-2-picture-box-background, .ready-to-load-img .truck-contract-2-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/truck-contract-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/truck-contract-2.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/truck-contract-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/truck-contract-2-2x.webp");
		}
	}
}

.truck-contract-2-picture-background-ratio, .truck-contract-2-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
