

.page-commercial {
	@import (reference) "commercial-images";
	.rock {
		.ready-to-load-img.commercial-rock-picture-box-background();
	}
	.utility {
		.ready-to-load-img.commercial-utility-picture-box-background;
	}
	.grading {
		.ready-to-load-img.commercial-grading-picture-box-background;
	}

}