
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.demo-municipal-1-picture img {
	width: 683px;
}

.ready-to-load-img .demo-municipal-1-picture-background, .ready-to-load-img .demo-municipal-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/municipal/demo-municipal-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/municipal/demo-municipal-1.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/municipal/demo-municipal-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/demo-municipal-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/municipal/demo-municipal-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/demo-municipal-1-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/municipal/demo-municipal-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/municipal/demo-municipal-1.webp");
			}
		}
	}
}
.ready-to-load-img .demo-municipal-1-picture-box-background, .ready-to-load-img .demo-municipal-1-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/municipal/demo-municipal-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/municipal/demo-municipal-1.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/municipal/demo-municipal-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/demo-municipal-1-2x.webp");
		}
	}
}

.demo-municipal-1-picture-background-ratio, .demo-municipal-1-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.municipal-aggregates-picture img {
	width: 683px;
}

.ready-to-load-img .municipal-aggregates-picture-background, .ready-to-load-img .municipal-aggregates-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/municipal/municipal-aggregates.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/municipal/municipal-aggregates.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn1}/img/municipal/municipal-aggregates-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/municipal/municipal-aggregates-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/municipal/municipal-aggregates-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/municipal/municipal-aggregates-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn1}/img/municipal/municipal-aggregates.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/municipal/municipal-aggregates.webp");
			}
		}
	}
}
.ready-to-load-img .municipal-aggregates-picture-box-background, .ready-to-load-img .municipal-aggregates-picture-box-background-with-ratio {
	background-image: url("@{cdn1}/img/municipal/municipal-aggregates.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/municipal/municipal-aggregates.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/municipal/municipal-aggregates-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/municipal/municipal-aggregates-2x.webp");
		}
	}
}

.municipal-aggregates-picture-background-ratio, .municipal-aggregates-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.municipal-roads-picture img {
	width: 683px;
}

.ready-to-load-img .municipal-roads-picture-background, .ready-to-load-img .municipal-roads-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/municipal/municipal-roads.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/municipal/municipal-roads.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn2}/img/municipal/municipal-roads-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/municipal/municipal-roads-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/municipal/municipal-roads-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/municipal/municipal-roads-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn2}/img/municipal/municipal-roads.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/municipal/municipal-roads.webp");
			}
		}
	}
}
.ready-to-load-img .municipal-roads-picture-box-background, .ready-to-load-img .municipal-roads-picture-box-background-with-ratio {
	background-image: url("@{cdn2}/img/municipal/municipal-roads.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/municipal/municipal-roads.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/municipal/municipal-roads-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/municipal/municipal-roads-2x.webp");
		}
	}
}

.municipal-roads-picture-background-ratio, .municipal-roads-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.municipal-utility-picture img {
	width: 683px;
}

.ready-to-load-img .municipal-utility-picture-background, .ready-to-load-img .municipal-utility-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/municipal/municipal-utility.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/municipal/municipal-utility.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn3}/img/municipal/municipal-utility-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal/municipal-utility-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/municipal/municipal-utility-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal/municipal-utility-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn3}/img/municipal/municipal-utility.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/municipal/municipal-utility.webp");
			}
		}
	}
}
.ready-to-load-img .municipal-utility-picture-box-background, .ready-to-load-img .municipal-utility-picture-box-background-with-ratio {
	background-image: url("@{cdn3}/img/municipal/municipal-utility.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/municipal/municipal-utility.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/municipal/municipal-utility-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/municipal/municipal-utility-2x.webp");
		}
	}
}

.municipal-utility-picture-background-ratio, .municipal-utility-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.road-construction-095-picture img {
	width: 683px;
}

.ready-to-load-img .road-construction-095-picture-background, .ready-to-load-img .road-construction-095-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/municipal/road-construction-095.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/municipal/road-construction-095.webp");
	}
	
	
	@media (max-width: 341.5px) {

		background-image: url("@{cdn0}/img/municipal/road-construction-095-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/road-construction-095-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/municipal/road-construction-095-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/road-construction-095-2x.webp");
		}
		@media (max-width: 341.5px) {

			background-image: url("@{cdn0}/img/municipal/road-construction-095.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/municipal/road-construction-095.webp");
			}
		}
	}
}
.ready-to-load-img .road-construction-095-picture-box-background, .ready-to-load-img .road-construction-095-picture-box-background-with-ratio {
	background-image: url("@{cdn0}/img/municipal/road-construction-095.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/municipal/road-construction-095.webp");
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/municipal/road-construction-095-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/municipal/road-construction-095-2x.webp");
		}
	}
}

.road-construction-095-picture-background-ratio, .road-construction-095-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}
