
.page-our-work {
	@import (multiple, reference) "home-images";
	@import (reference) "our-work-images";
	.residential {
		.ready-to-load-img.residential-picture-box-background();
	}
	.commercial {
		.ready-to-load-img.commercial-picture-box-background();
	}
	.municipal {
		.ready-to-load-img.municipal-picture-box-background();
	}
	.trucking {
		.ready-to-load-img.trucking-picture-box-background();
	}
	.aggregates {
		.ready-to-load-img.aggregates-picture-box-background();
	}
	.crushing {
		.ready-to-load-img.crushing-picture-box-background();
	}
	.runway {
		.ready-to-load-img.runway-picture-box-background();
	}



	.aggg-1 {
		.ready-to-load-img.aggg-1-picture-box-background();
	}


	.aggg-boulders-1 {
		.ready-to-load-img.aggg-boulders-1-picture-box-background();
	}


	.aggg-boulders-2 {
		.ready-to-load-img.aggg-boulders-2-picture-box-background();
	}


	.aggg-pea-rock-1 {
		.ready-to-load-img.aggg-pea-rock-1-picture-box-background();
	}


	.aggg-recycled-asphalt {
		.ready-to-load-img.aggg-recycled-asphalt-picture-box-background();
	}


	.aggg-screened-black {
		.ready-to-load-img.aggg-screened-black-picture-box-background();
	}


	.aggg-washed-round-rock-2 {
		.ready-to-load-img.aggg-washed-round-rock-2-picture-box-background();
	}


	.aggg-washed-round-rock {
		.ready-to-load-img.aggg-washed-round-rock-picture-box-background();
	}


	.aggg-washed-sand {
		.ready-to-load-img.aggg-washed-sand-picture-box-background();
	}


	.our-work-commercial-01 {
		.ready-to-load-img.our-work-commercial-01-picture-box-background();
	}


	.our-work-commercial-02 {
		.ready-to-load-img.our-work-commercial-02-picture-box-background();
	}


	.our-work-commercial-03 {
		.ready-to-load-img.our-work-commercial-03-picture-box-background();
	}


	.our-work-commercial-04 {
		.ready-to-load-img.our-work-commercial-04-picture-box-background();
	}


	.our-work-commercial-05 {
		.ready-to-load-img.our-work-commercial-05-picture-box-background();
	}


	.our-work-commercial-06 {
		.ready-to-load-img.our-work-commercial-06-picture-box-background();
	}


	.our-work-commercial-07 {
		.ready-to-load-img.our-work-commercial-07-picture-box-background();
	}


	.our-work-commercial-08 {
		.ready-to-load-img.our-work-commercial-08-picture-box-background();
	}


	.our-work-commercial-09 {
		.ready-to-load-img.our-work-commercial-09-picture-box-background();
	}


	.our-work-commercial-10 {
		.ready-to-load-img.our-work-commercial-10-picture-box-background();
	}


	.our-work-commercial-11 {
		.ready-to-load-img.our-work-commercial-11-picture-box-background();
	}


	.our-work-commercial-12 {
		.ready-to-load-img.our-work-commercial-12-picture-box-background();
	}


	.our-work-commercial-13 {
		.ready-to-load-img.our-work-commercial-13-picture-box-background();
	}


	.our-work-commercial-14 {
		.ready-to-load-img.our-work-commercial-14-picture-box-background();
	}


	.our-work-commercial-15 {
		.ready-to-load-img.our-work-commercial-15-picture-box-background();
	}


	.our-work-commercial-16 {
		.ready-to-load-img.our-work-commercial-16-picture-box-background();
	}


	.our-work-commercial-17 {
		.ready-to-load-img.our-work-commercial-17-picture-box-background();
	}


	.our-work-commercial-18 {
		.ready-to-load-img.our-work-commercial-18-picture-box-background();
	}


	.our-work-commercial-19 {
		.ready-to-load-img.our-work-commercial-19-picture-box-background();
	}


	.our-work-commercial-20 {
		.ready-to-load-img.our-work-commercial-20-picture-box-background();
	}


	.our-work-commercial-21 {
		.ready-to-load-img.our-work-commercial-21-picture-box-background();
	}


	.our-work-commercial-22 {
		.ready-to-load-img.our-work-commercial-22-picture-box-background();
	}


	.our-work-crushing-01 {
		.ready-to-load-img.our-work-crushing-01-picture-box-background();
	}


	.our-work-crushing-02 {
		.ready-to-load-img.our-work-crushing-02-picture-box-background();
	}


	.our-work-crushing-03 {
		.ready-to-load-img.our-work-crushing-03-picture-box-background();
	}


	.our-work-crushing-04 {
		.ready-to-load-img.our-work-crushing-04-picture-box-background();
	}


	.our-work-crushing-05 {
		.ready-to-load-img.our-work-crushing-05-picture-box-background();
	}


	.our-work-crushing-06 {
		.ready-to-load-img.our-work-crushing-06-picture-box-background();
	}


	.our-work-crushing-07 {
		.ready-to-load-img.our-work-crushing-07-picture-box-background();
	}


	.our-work-crushing-08 {
		.ready-to-load-img.our-work-crushing-08-picture-box-background();
	}


	.our-work-crushing-09 {
		.ready-to-load-img.our-work-crushing-09-picture-box-background();
	}


	.our-work-crushing-10 {
		.ready-to-load-img.our-work-crushing-10-picture-box-background();
	}


	.our-work-crushing-11 {
		.ready-to-load-img.our-work-crushing-11-picture-box-background();
	}


	.our-work-crushing-12 {
		.ready-to-load-img.our-work-crushing-12-picture-box-background();
	}


	.our-work-crushing-13 {
		.ready-to-load-img.our-work-crushing-13-picture-box-background();
	}


	.our-work-crushing-14 {
		.ready-to-load-img.our-work-crushing-14-picture-box-background();
	}


	.our-work-municipal-01 {
		.ready-to-load-img.our-work-municipal-01-picture-box-background();
	}


	.our-work-municipal-02 {
		.ready-to-load-img.our-work-municipal-02-picture-box-background();
	}


	.our-work-municipal-03 {
		.ready-to-load-img.our-work-municipal-03-picture-box-background();
	}


	.our-work-municipal-04 {
		.ready-to-load-img.our-work-municipal-04-picture-box-background();
	}


	.our-work-municipal-05 {
		.ready-to-load-img.our-work-municipal-05-picture-box-background();
	}


	.our-work-municipal-06 {
		.ready-to-load-img.our-work-municipal-06-picture-box-background();
	}


	.our-work-municipal-07 {
		.ready-to-load-img.our-work-municipal-07-picture-box-background();
	}


	.our-work-municipal-08 {
		.ready-to-load-img.our-work-municipal-08-picture-box-background();
	}


	.our-work-municipal-09 {
		.ready-to-load-img.our-work-municipal-09-picture-box-background();
	}


	.our-work-municipal-10 {
		.ready-to-load-img.our-work-municipal-10-picture-box-background();
	}


	.our-work-municipal-11 {
		.ready-to-load-img.our-work-municipal-11-picture-box-background();
	}


	.our-work-municipal-12 {
		.ready-to-load-img.our-work-municipal-12-picture-box-background();
	}


	.our-work-municipal-13 {
		.ready-to-load-img.our-work-municipal-13-picture-box-background();
	}


	.our-work-residential-01 {
		.ready-to-load-img.our-work-residential-01-picture-box-background();
	}


	.our-work-residential-02 {
		.ready-to-load-img.our-work-residential-02-picture-box-background();
	}


	.our-work-residential-03 {
		.ready-to-load-img.our-work-residential-03-picture-box-background();
	}


	.our-work-residential-04 {
		.ready-to-load-img.our-work-residential-04-picture-box-background();
	}


	.our-work-residential-05 {
		.ready-to-load-img.our-work-residential-05-picture-box-background();
	}


	.our-work-residential-06 {
		.ready-to-load-img.our-work-residential-06-picture-box-background();
	}


	.our-work-residential-07 {
		.ready-to-load-img.our-work-residential-07-picture-box-background();
	}


	.our-work-residential-08 {
		.ready-to-load-img.our-work-residential-08-picture-box-background();
	}


	.our-work-residential-09 {
		.ready-to-load-img.our-work-residential-09-picture-box-background();
	}


	.our-work-residential-10 {
		.ready-to-load-img.our-work-residential-10-picture-box-background();
	}


	.our-work-residential-11 {
		.ready-to-load-img.our-work-residential-11-picture-box-background();
	}


	.our-work-residential-12 {
		.ready-to-load-img.our-work-residential-12-picture-box-background();
	}


	.our-work-residential-13 {
		.ready-to-load-img.our-work-residential-13-picture-box-background();
	}


	.our-work-residential-14 {
		.ready-to-load-img.our-work-residential-14-picture-box-background();
	}


	.our-work-trucking-01 {
		.ready-to-load-img.our-work-trucking-01-picture-box-background();
	}


	.our-work-trucking-02 {
		.ready-to-load-img.our-work-trucking-02-picture-box-background();
	}


	.our-work-trucking-03 {
		.ready-to-load-img.our-work-trucking-03-picture-box-background();
	}


	.our-work-trucking-04 {
		.ready-to-load-img.our-work-trucking-04-picture-box-background();
	}


	.our-work-trucking-05 {
		.ready-to-load-img.our-work-trucking-05-picture-box-background();
	}


	.our-work-trucking-06 {
		.ready-to-load-img.our-work-trucking-06-picture-box-background();
	}


	.our-work-trucking-07 {
		.ready-to-load-img.our-work-trucking-07-picture-box-background();
	}


	.our-work-trucking-08 {
		.ready-to-load-img.our-work-trucking-08-picture-box-background();
	}


	.our-work-trucking-09 {
		.ready-to-load-img.our-work-trucking-09-picture-box-background();
	}


	.our-work-trucking-10 {
		.ready-to-load-img.our-work-trucking-10-picture-box-background();
	}


	.our-work-trucking-11 {
		.ready-to-load-img.our-work-trucking-11-picture-box-background();
	}


	.our-work-trucking-12 {
		.ready-to-load-img.our-work-trucking-12-picture-box-background();
	}


	.our-work-trucking-13 {
		.ready-to-load-img.our-work-trucking-13-picture-box-background();
	}


	.our-work-trucking-14 {
		.ready-to-load-img.our-work-trucking-14-picture-box-background();
	}


	.our-work-trucking-15 {
		.ready-to-load-img.our-work-trucking-15-picture-box-background();
	}


	.our-work-trucking-16 {
		.ready-to-load-img.our-work-trucking-16-picture-box-background();
	}


	.our-work-trucking-17 {
		.ready-to-load-img.our-work-trucking-17-picture-box-background();
	}



}