.box-headings {
	grid-auto-flow: column dense;
	.set {
		display: contents;
	}
	.box {
		background-position: center;
		background-size: cover;
		text-decoration: none;
		position: relative;
		
		h2 {
			.phudu(55);
			color: white;
			position: absolute;
			margin-bottom: 0;
			left: @side-gutter;
			bottom: @middle-gutter;
			right: @middle-gutter;
			.tablet-and-smaller({
				left: @minimum-gutter;
				right: @minimum-gutter;
				font-size: 5vw;
				font-size: clamp(25pt, 6vw, 45pt);
			});

		}
	}
	
	.content {
		margin-top: 70px;
	}
	.tablet({
		.set:nth-child(2n + 1) {
			.box {
				grid-column: 2 / span 2;
				aspect-ratio: 1;
			}
			.content {
				grid-column: 3;
				margin-right: @middle-gutter;
			}
			&:last-of-type {
				.content {
					grid-column: 5;
					align-self: center
				}
			}
		}
		.set:nth-child(2n) {
			.box {
				grid-column: 4 / span 3;
			}
			.content {
				grid-column: 5;
			}
		}
	});
	
	.tablet-and-smaller({
		.set {
			.box {
				grid-column: 2 / span 5;
				aspect-ratio: 1;
			}
			.content {
				grid-column: 3 / span 3;
			}
		}
	});

}
.box-links {
	grid-auto-flow: column dense;
	.set {
		display: contents;
	}
	.box {
		background-position: center;
		background-size: cover;
		text-decoration: none;
		position: relative;
		
		
		.txt {
			position: absolute;
			
			left: 60px;
			right: 60px;
			bottom: 40px;
			border-top: solid 2px @green-main;
			border-right: solid 2px @green-main;
			border-left: solid 2px @green-main;
			border-bottom: solid 2px @green-main;

			background-color: fade(@tan-light, 30%);
			color: white;
			padding: 30px;

			h1 {
				.phudu(70);
				height: 2.8em;
				line-height: 1.3em;
				margin: 0;
				text-align: center
			}
		}
		.center-txt {
			position: absolute;
			
			left: @side-gutter;
			right: 60px;
			top: 50%;
			transform: translateY(-50%);

			color: white;
			h1 {
				.phudu(80);
				line-height: 1em;
				margin: 0;
				text-align: left;
			}
			
			a {
				.raleway(18);
				color: white;
				text-decoration: none;
				font-weight: 600;
			}
			.tablet-and-smaller({
				left: @minimum-gutter;
				right: @minimum-gutter;

			});
		}
		.full-txt {
			.txt;
			// top: 50px;
			bottom: 0;
			border-bottom: 0;
			padding: 8% 9% 4% 9%;

			h1 {
				height: auto;
				text-align: left;
				line-height: 1em;
			}
			

			
			h4 {
				.raleway(18);
				line-height: 2em;

				strong {
					.phudu(22);
					text-transform: uppercase;
				}
			}
			
			&.no-color {
				background: none;
				border: 0;
			}
			
			.tablet-and-smaller({
				left: 0;
				right: 0;
			});
			
			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}
	
	.tablet({
		.set:nth-child(2n + 1) {
			.box {
				grid-column: 2 / span 2;
				aspect-ratio: 1;
			}
			&:last-of-type {
				.content {
					grid-column: 5;
					align-self: center
				}
			}
		}
		.set:nth-child(2n) {
			.box {
				grid-column: 4 / span 3;
			}
		}
	});
	@media (min-width: @tablet-width) and (max-width: 1300px)  {
		.set {
			.box {
				h1 {
					.phudu(40);
				}
			}
		}
	}
	
	.tablet-and-smaller({
		.set {
			.box {
				h1 {
					font-size: 8vw;
					font-size: clamp(20pt, 10vw, 45pt);
				}
				h4 {
					font-size: 4vw;
					font-size: clamp(10pt, 4vw, 30pt);
				}
				grid-column: 2 / span 5;
				aspect-ratio: 1;
			}
		}
	});
	.phone-and-smaller({
		.set {
			.box {
				h1 {
					font-size: clamp(10pt, 6vw, 45pt);
					br {
						display: none;
					}
				}
				.full-txt {
					h1 {
						font-size: clamp(20pt, 10vw, 45pt);
					}

				}
			}
		}
	});

}

.box.shiner-show {
	.slide {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background-position: center;
		background-size: cover;
	}
	
	&.transitions-on {
		.slide {
			transition: opacity 2s;
		}
	}

	.slide.invisible {
		opacity: 0;
	}

	.slide.visible {
		opacity: 1;
	}
	
	.controls {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3;
		color: white;
		
		span {
			cursor: pointer;
			padding: 20px;
		}
		
		.dot {
			@size: 20px;
			width: @size;
			height: @size;
			border-radius: 50%;
			border: solid 2px white;
			
			&.current {
				background-color: white;
			}
		}
	}
}

.content-module-box {
	.pic, .cta {
		font-size: 0;
	}
	.cta {
		a {
			font-size: 1rem;
		}
	}
	
	.pic-show {
		margin-top: 40px;
		margin-bottom: 40px;
		display: grid;
		column-gap: 20px;
		row-gap: 20px;
		.pic {
			aspect-ratio: 16 / 9;
			.full-back;
		}

	}
	
	.pic-side {
		.pic-show();
		.tablet({
			grid-template-columns: repeat(2, 1fr);	
			.content {
				& > h2 {
					margin-top: 0;
				}
			}

		});
		.tablet-and-smaller({
			.pic {
				width: 100%;
				margin: auto;
			}
		});
	}
	
	&.pic-top {
		.pic-show();

		.pic {
			width: 100%;
			margin: auto;
		}
	}

	&.pic-left {
		.pic-side();
	}

	&.pic-right {
		.pic-side();
		.tablet({
			grid-auto-flow: dense;
			.pic {
				grid-column: 2;
			}
			.content {
				grid-column: 1;
			}
		})
	}
}

.green-button {
	display: inline-block;
	padding-left: clamp(10px, 68px, 15%);
	padding-right: clamp(10px, 68px, 15%);
	padding-top: 25px;
	padding-bottom: 25px;
	background-color: @green-main;
	border: solid 3px @tan-light;
	color: white;
	font-weight: 600;
	text-decoration: none;
	transition: background-color .3s;
	text-align: center;
	
	&:hover {
		background-color: @green-dark;
	}
	
}

.page-header {
	.background-mask {
		display: none;
	}
	.container {
		grid-column: 2 / span 5;
		background-size: cover;
		background-position: center center;
		padding-left: @side-gutter;
		padding-right: @side-gutter;
		.tablet-and-smaller({
			padding-left: @minimum-gutter;
			padding-right: @minimum-gutter;
		});
	}
	h1, h2 {
		color: white;
	}
}

.five-star-quote {
	margin-top: 80px;
	margin-bottom: 80px;
	
	.background {
		background-color: @green-dark;
		grid-column: 2 / span 5;
		grid-row: 1;
	}
	.quote {
		grid-row: 1;
		grid-column: 3 / span 3;
	}
	.quote {
		color: white;
		padding-top: 50px;
		padding-bottom: 80px;
		h2 {
			.raleway(37);
			line-height: 1.2em;
			font-style: italic
		}
		p {
			.raleway(22);
			line-height: 1.636363636em;
		}
	}
	@media (min-width: 1340px) {
		.background {
			.ready-to-load-img.testimonial-green-picture-box-background();
			background-position: right center;
			background-size: cover;
		}
		.quote {
			grid-column: 3;
			padding-left: @middle-gutter;
		}
	}
}

.msg-and-image {
	display: grid;
	grid-template-columns: auto minmax(200px, 60%);
	column-gap: 12px;
	
	.img {
		background-size: cover;	
		background-position: center center;
	}
	
	@container (max-width: 440px) {
		grid-template-columns: auto;
		.img {
			aspect-ratio: 1;
		}
	}
	
}

.kte-tan-background {
	margin-top: 0;
	&:before {
		content: '\00a0';
		grid-column: 2 / span 5;
		grid-row: 1;
		.ready-to-load-img.kte-tan-background-picture-box-background();
		background-position: center center;
		background-size: cover;
	}
	.container-left, .container-right {
		grid-row: 1;
	}
	.container-left {
		aspect-ratio: 1;
	}
	.container-right {
		align-self: center;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
	h2 {
		.raleway(37);
		color: @green-tan-box !important;
		font-style: italic;
	}
	p {
		color: @green-tan-box !important;
	}
}

.kte-tan-background + .five-star-quote {
	margin-top: 0;
}

.time-period {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 40px;
	margin-bottom: 20px;
	
	.year {
		.phudu(60);
		color: @green-main;
	}
	.line {
		border-bottom: solid 1px @green-main;
		margin-bottom: 7px;
	}
}

.contact-form {
	label {
		display: block;
		margin: 10px 0;
		
		&.hidden-text {
			font-size: 0;
			input, textarea {
				.raleway(18);
			}
		}
		&.checkbox {
			margin: 20px 0;

			a {
				color: @tan-dark;
			}
			display: grid;
			grid-template-columns: auto 1fr;
			input {
				align-self: start;
				margin-top: 3px;
			}
		}
	}
	textarea, input[type=text], select {
		width: 100%;
		box-sizing: border-box;
		border: solid 2px black;
		padding: 8px 16px;
	}
	input[type=checkbox] {
		margin-right: 10px;
	}
	button[type=submit] {
		margin-top: 30px;
	}
}

.pit-locations {
	container-type: inline-size;
	img {
		max-width: 100%;
	}
}
.pits-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@container (min-width: 500px) {
		grid-template-columns: repeat(3, 1fr);
	}
	column-gap: 0;
	row-gap: 0px;
	.pit-cell {
		border: solid 1px black;
		text-align: center;
		padding: 5px;
		.count {
			.phudu(40);
			color: @green-main;
			text-align: center;
			padding-top: 15px;
		}
		.title {
			.raleway(20);
			font-weight: 600;
			text-align: center;
			color: black;
			margin: 10px 0;
		}
		.hours {
			color: @green-main;
			font-weight: 300;
			text-align: center;
			margin-bottom: 26px;

		}
		.address {
			.raleway(15);
			padding: 5px 3px;

		}

	}
}